<template>
  <div id="container">
    <Header></Header>
    <!-- <div style="right:10px;position:absolute">
      <span>3d测试</span>
      <input style="width:1000px" type="file" id="upload_gds" accept=".gds">
    </div> -->
    <div v-show="loading" class="mask">
      <el-table v-loading="loading" :element-loading-text="$t('messages.waitLoading')" style="width: 100%; height: 100%"
        background="#fff">
      </el-table>
    </div>
    <div class="main-section">
      <!--左边工艺步骤菜单-->
      <div v-if="structureList.length">
        <div class="left-content">
          <div class="cardHeader">
            <span>{{$t('messages.view3D.ui.process_steps')}}</span>
          </div>
          <div class="step-card" v-for="(item,i) in stepList" :class="{'step-active':item.index == currentStepNumber}">
            <div class="step-content" @click="stepChange(item.index)">
              <div class="step-head-container">
                <div class="step-number">{{item.index}}</div>
                <div v-if="$i18n.locale == 'zh'" style=" margin-left: 7px;">{{item.step}}</div>
                <div v-if="$i18n.locale == 'en'" style=" margin-left: 7px;">{{item.step_en}}</div>
              </div>
              <div v-if="$i18n.locale == 'zh'" class="step-text-content">
                {{item.text}}
              </div>
              <div v-if="$i18n.locale == 'en'" class="step-text-content">
                {{item.text_en}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--3D视图-->
      <div id="container-3d" class="container-3d"></div>
      <!--右边结构层列表菜单-->
      <div class="right-content" v-if="showRight">
        <div v-if="!structureList.length" style="padding-left:60px;padding-top: 30px;">
          <el-button type="el-button--default loadCraftButton" style="" @click="loadFileDialogVisible = true">
            <span class="iconfont icon-gongyi" style="font-size:20px"></span>
            <span class="gongyi-text">{{$t('messages.view3D.ui.load_file')}}</span>
          </el-button>
        </div>
        <div v-if="structureList.length">
          <!-- <div data-v-fae5bece="" id="rightDragBtn" @click="hideRight"><img alt="" data-v-fae5bece=""
            :src="showRight?require('../../assets/icons/file/shousuo-zuo.svg'):require('../../assets/icons/file/shousuo-you.svg')"
            draggable="false"></div> -->
          <div class="cardHeader"><span>{{$t('messages.view3D.ui.structure_list')}}</span></div>
          <el-table id="structure-table" :data="structureList" @header-click="hideAll" stripe>
            <el-table-column width='139' align="left" prop="structure" :label="$t('messages.view3D.ui.structure')">
              <template slot-scope="scope">
                <!-- <div data-v-0afda541="" class="fillColor__item" style="background: rgb(0, 128, 128);">
              </div> -->
                <el-color-picker v-if="scope.row.Step !== 'etch'" size="mini" show-alpha v-model="scope.row.rgba"
                  @change="structureColorChnage">
                </el-color-picker>
                <div v-if="scope.row.Step == 'etch'" style="width: 28px;height: 28px;"></div>
                <span>{{scope.row.MaterialLabel}}</span>
              </template>
            </el-table-column>
            <el-table-column width='51' align="center" :label="$t('messages.view3D.ui.hide')">
              <template slot-scope="scope">
                <img alt="" @click="scope.row.hide=true;hideClick(scope.row);" class="hide-icon"
                  src="../../assets/icons/layer/查看.svg" v-if="!scope.row.hide" />
                <img alt="" @click="scope.row.hide=false;hideClick(scope.row);" class="hide-icon"
                  src="../../assets/icons/layer/隐藏.svg" v-else />
              </template>
            </el-table-column>
            <el-table-column width='52' align="right" prop="LayerNum"
              :label="$t('messages.view3D.ui.number')"></el-table-column>
          </el-table>
          <!--剖面图盒子生成器-->
          <div>
            <div class="cardHeader">
              <!-- 头部标题 -->
              <strong>{{$t('messages.view3D.ui.light')}}</strong>
              <div class="arrow" @click="showLightConfig=!showLightConfig">
                <span class="el-icon-arrow-down" v-if="showLightConfig"></span>
                <span class="el-icon-arrow-up" v-else></span>
              </div>
            </div>
            <div v-show="showLightConfig">
              <div class="slider-container">
                <div class="slider-title slider-light">{{$t('messages.view3D.ui.ambient_light_intensity')}}</div>
                <el-slider v-model="lightParam.ambientStrength" :min="lightConfig.ambientStrength.min"
                  :max="lightConfig.ambientStrength.max" show-input @change="lightChange">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title slider-light">{{$t('messages.view3D.ui.direct_light_intensity')}}</div>
                <el-slider v-model="lightParam.directStrength" :min="lightConfig.directStrength.min"
                  :max="lightConfig.directStrength.max" show-input @change="lightChange">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title slider-light">{{$t('messages.view3D.ui.direct_light_angle')}}</div>
                <el-slider v-model="lightParam.directAngle" :min="lightConfig.directAngle.min"
                  :max="lightConfig.directAngle.max" show-input @change="lightChange">
                </el-slider>
              </div>
            </div>
            <div class="cardHeader">
              <!-- 头部标题 -->
              <strong>{{$t('messages.view3D.ui.model_thickness')}}</strong>
              <div class="arrow" @click="showScaleYSection=!showScaleYSection">
                <span class="el-icon-arrow-down" v-if="showScaleYSection"></span>
                <span class="el-icon-arrow-up" v-else></span>
              </div>
            </div>
            <div v-show="showScaleYSection" class="scaleYControl">
              <div class="slider-container">
                <div class="slider-title">{{$t('messages.view3D.ui.thickness')}}</div>
                <el-slider v-model="cutBoxParam.scaleY" :min="cutBoxConfig.scaleY.min" :max="cutBoxConfig.scaleY.max"
                  show-input @change="scaleYChange">
                </el-slider>
              </div>
            </div>
            <div class="cardHeader">
              <!-- 头部标题 -->
              <strong>{{$t('messages.view3D.ui.profile')}}</strong>
              <div class="arrow" @click="showCutSection=!showCutSection">
                <span class="el-icon-arrow-down" v-if="showCutSection"></span>
                <span class="el-icon-arrow-up" v-else></span>
              </div>
            </div>
            <div v-show="showCutSection" class="cutControl">
              <div class="slider-container">
                <div class="slider-title">{{$t('messages.view3D.ui.profile_area')}}</div>
                <el-switch v-model="showCutBox" active-color="#0C7DE6" style="margin-left:120px"
                  @change="showCutBoxChange">
                </el-switch>
              </div>
              <div class="slider-container">
                <div class="slider-title">{{$t('messages.view3D.ui.height')}}</div>
                <el-slider v-model="cutBoxParam.width" :min="cutBoxConfig.w.min" :max="cutBoxConfig.w.max" show-input
                  @change="cutBoxChange">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title">{{$t('messages.view3D.ui.width')}}</div>
                <el-slider v-model="cutBoxParam.height" :min="cutBoxConfig.h.min" :max="cutBoxConfig.h.max" show-input
                  @change="cutBoxChange">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title">{{$t('messages.view3D.ui.depth')}}</div>
                <el-slider v-model="cutBoxParam.depth" show-input @change="cutBoxChange">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title" style="padding-left:20px">X</div>
                <el-slider v-model="cutBoxParam.x" show-input @change="cutBoxChange" :min="cutBoxConfig.x.min"
                  :max="cutBoxConfig.x.max">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title" style="padding-left:20px">Y</div>
                <el-slider v-model="cutBoxParam.y" show-input @change="cutBoxChange" :min="cutBoxConfig.y.min"
                  :max="cutBoxConfig.y.max">
                </el-slider>
              </div>
              <div class="slider-container">
                <div class="slider-title" style="padding-left:20px">Z</div>
                <el-slider v-model="cutBoxParam.z" show-input @change="cutBoxChange" :min="cutBoxConfig.z.min"
                  :max="cutBoxConfig.z.max">
                </el-slider>
              </div>
              <el-button type="primary" style="margin-left:16px"
                @click="generateCutMesh">{{$t('messages.view3D.ui.generate_profile')}}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="rightMenu">
        <div class="readOnlyMask" v-if="readOnly"></div>
        <div class="active rightMenu__item">{{$t('messages.view3D.ui.list')}}</div>
      </div>
    </div>
    <!-- 工艺文件加载弹窗 !-->
    <el-dialog :title="$t('messages.view3D.ui.load_file')" :visible.sync="loadFileDialogVisible" width="30%">
      <div>
        <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-change="uploadCraftFile"
          :auto-upload="false" :limit="1" :file-list="fileList" :on-remove="handleRemove">
          <el-button size="small" type="primary"
            class="choose-file-button">{{$t('messages.view3D.ui.choose')}}</el-button>
        </el-upload>
      </div>
      <div class="el-dialog__footer">
        <span actionslot="footer" class="dialog-footer">
          <el-button type="primary" class="dialog-button" :disabled='!fileList.length'
            @click="loadCraftFile()">{{$t('messages.view3D.ui.ok')}}
          </el-button>
          <el-button class="dialog-button dialog-button-cancel"
            @click="loadFileDialogVisible = false">{{$t('messages.view3D.ui.cancel')}}</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/homes/header/Header.vue";
import { Qeda3DLayout } from "@/utils/3d/qeda-3d-layout";
import { getRectanglePoints } from "@/utils/3d/mesh-generator";
import bus from "../common/bus";
export default {
  components: { Header },
  data() {
    // 在这里定义layout避免被转成响应式,影响性能
    this.layout = null;
    return {
      loading: false,
      readOnly: false,
      showLeft: true,
      showRight: true,
      showCutSection: true,
      showScaleYSection: true,
      showCutBox: true,
      showLightConfig: true,
      siderValue: 0,
      uploadJsonFileData: [],
      hideAllObjs: false,
      fileList: [],
      cutBoxParam: {
        width: 0,
        height: 0,
        depth: 2,
        x: 0,
        y: 0,
        z: 0,
        scaleY: 1,
      },
      cutBoxConfig: {
        w: { min: 0, max: 100 },
        h: { min: 0, max: 100 },
        x: { min: -100, max: 100 },
        y: { min: -100, max: 100 },
        z: { min: -100, max: 100 },
        scaleY: { min: 1, max: 100000 },
      },
      lightParam: {
        ambientStrength: 3,
        directStrength: 1,
        directAngle: 0,
        directAngleBk: 0,
      },
      lightConfig: {
        ambientStrength: { min: 0, max: 10 },
        directStrength: { min: 0, max: 10 },
        directAngle: { min: -180, max: 180 },
      },
      loadFileDialogVisible: false,
      structureData: [],
      structureList: [],
      stepList: [],
      currentStepNumber: null,
      viewCutModel: false,
    };
  },
  mounted() {
    this.init();
    //加载工艺文件
    bus.$on("loadCraftFile", (data) => {

      this.loadFileDialogVisible = true;
    });
    //重置3D模型
    bus.$on("reset3dModel", (data) => {

      this.viewCutModel = false;
      this.layout.resetModel(this.currentStepNumber);
    });
  },
  methods: {
    init() {

      let cell = null;
      let storeCell = window.opener["3DViewCell"];
      if (storeCell) {
        let polygons = storeCell.get_polygons();
        cell = new QGdstk.Cell("3DViewCell");
        let len = polygons.length;
        for (let i = 0; i < len; i++) {
          const from = polygons[i];
          let copy = new QGdstk.Polygon(from.get_points());
          copy.layer = from.layer;
          copy.datatype = from.datatype;
          cell.add(copy);
        }
      }

      this.layout = new Qeda3DLayout(cell);
      let box_params = window.opener["3DViewCellCutBox"];
      if (box_params) {
        this.cutBoxParam.width = box_params.w;
        this.cutBoxParam.height = box_params.h;
        this.cutBoxParam.x = box_params.center[0];
        this.cutBoxParam.y = box_params.center[1];
      }
      let aabb = cell.bounding_box();
      if (aabb) {
        let points = getRectanglePoints(aabb, 100);
        let rootBoxAABB = new QGdstk.Polygon(points).bounding_box();
        this.cutBoxConfig.w.max = rootBoxAABB[1][0] - rootBoxAABB[0][0];
        this.cutBoxConfig.h.max = rootBoxAABB[1][1] - rootBoxAABB[0][1];
        this.cutBoxConfig.x.min = rootBoxAABB[0][0];
        this.cutBoxConfig.x.max = rootBoxAABB[1][0];
        this.cutBoxConfig.y.min = rootBoxAABB[0][1];
        this.cutBoxConfig.y.max = rootBoxAABB[1][1];
        if (box_params) {
          this.cutBoxConfig.w.max =
            box_params.w > this.cutBoxConfig.w.max
              ? box_params.w
              : this.cutBoxConfig.w.max;
          this.cutBoxConfig.h.max =
            box_params.h > this.cutBoxConfig.h.max
              ? box_params.h
              : this.cutBoxConfig.h.max;
          this.cutBoxConfig.x.max =
            box_params.center[0] > this.cutBoxConfig.x.max
              ? box_params.center[0]
              : this.cutBoxConfig.x.max;
          this.cutBoxConfig.x.min =
            box_params.center[0] < this.cutBoxConfig.x.min
              ? box_params.center[0]
              : this.cutBoxConfig.x.min;
          this.cutBoxConfig.y.max =
            box_params.center[1] > this.cutBoxConfig.y.max
              ? box_params.center[1]
              : this.cutBoxConfig.y.max;
          this.cutBoxConfig.y.min =
            box_params.center[1] < this.cutBoxConfig.y.min
              ? box_params.center[1]
              : this.cutBoxConfig.y.min;
        }
      }
      this.cutBoxChange();
      if (window.opener["CrossSectionProcess"]) {
        let data = window.opener["CrossSectionProcess"];
        data.forEach((obj) => {
          if (Array.isArray(obj)) {
            obj.forEach((child) => this.initColor(child));
          } else {
            this.initColor(obj);
          }
        });
        this.uploadJsonFileData = data;

        setTimeout(() => {
          this.loadCraftFile();
        }, 1000);
      }
    },
    hideClick(data) {
      this.hideGroup(this.layout.groups);
      this.hideGroup(this.layout.cutGroups);
    },
    //隐藏模型
    hideGroup(groups) {

      groups.forEach((group) => {
        if (Array.isArray(group)) {
          group.forEach((sub_group) => {
            let res = this.structureList.filter(
              (attr) =>
                attr.LayerNum == sub_group.layerNumber &&
                attr.MaterialLabel == sub_group.structurMaterial
            );


            if (res.length) {
              sub_group.visible = !res[0].hide;
            }
          });
        } else {
          let res = this.structureList.filter(
            (attr) => attr.MaterialLabel == group.structurMaterial
          );
          group.visible = !res[0].hide;
        }
      });
    },
    //结构层颜色修改
    structureColorChnage() {


      this.structureList.forEach((obj, index) => {
        if (obj.Color) {
          if (!obj.rgba) {
            obj.rgba = "rgba(0,0,0,0)";
          }
          let res = this.rgba2hex(obj.rgba);
          obj.Color = res.hex;
          obj.opacity = res.alpha;
        }
      });
      this.mapStructureData();
      this.structureData.forEach((obj, i) => {
        if (Array.isArray(obj)) {
          obj.forEach((child, j) => {
            if (child.Color) {
              this.layout.WORK_PROCEDURE[i][j].procedureColor = child.Color;
              this.layout.WORK_PROCEDURE[i][j].opacity = child.opacity;
              this.layout.WORK_PROCEDURE[i][j].material.color.set(child.Color);
              this.layout.WORK_PROCEDURE[i][j].material.opacity = child.opacity;
            }
          });
        } else {
          if (obj.Color) {
            this.layout.WORK_PROCEDURE[i].procedureColor = obj.Color;
            this.layout.WORK_PROCEDURE[i].opacity = obj.opacity;
            this.layout.WORK_PROCEDURE[i].material.color.set(obj.Color);
            this.layout.WORK_PROCEDURE[i].material.opacity = obj.opacity;
            if (i == 1) {

            }
          }
        }
      });
    },
    mapStructureData() {
      for (let i = 0; i < this.structureData.length; i++) {
        const obj = this.structureData[i];
        if (Array.isArray(obj)) {
          for (let j = 0; j < obj.length; j++) {
            const child = obj[j];
            let res = this.structureList.filter(
              (item) =>
                item.LayerNum == child.LayerNum && item.Depth == child.Depth
            );
            if (res.length) {
              this.structureData[i][j] = res[0];
            }
          }
        } else {
          let res = this.structureList.filter(
            (item) => item.LayerNum == obj.LayerNum && item.Depth == obj.Depth
          );
          if (res.length) {
            this.structureData[i] = res[0];
          }
        }
      }
    },
    //上传验证文件格式
    beforeFileUpload(file) {
      const isJSON = file.type === "application/json";
      if (!isJSON) {
        this.$message.error(
          this.$t("messages.view3D.ui.process_file_type_not_json_error")
        );
      }
      return isJSON;
    },
    //生成剖面模型
    generateCutMesh() {

      if (
        !this.cutBoxParam.width ||
        !this.cutBoxParam.height ||
        !this.cutBoxParam.depth
      ) {
        return;
      }
      this.loading = true;
      this.viewCutModel = true;
      setTimeout(() => {
        this.layout.generateCutView(this.currentStepNumber);
        this.scaleYChange();
        this.loading = false;
      }, 500);
    },
    //上传工艺文件
    async uploadCraftFile() {
      let event = event || window.event; //NOSONAR
      let file = event.target.files[0];
      this.fileList = [file];

      let suffix = file.name.substring(file.name.lastIndexOf(".") + 1); //json

      if (file.type !== "application/json") return;
      const __this = this;
      let reader = new FileReader(); // 新建一个FileReader
      reader.readAsText(file, "UTF-8"); // 读取文件
      let result = new Promise((resolve) => {
        reader.onload = (evt) => {
          resolve(evt);
        };
      });
      await result.then((evt) => {
        let fileString = evt.target.result; // 读取文件内容
        const jsonObj = JSON.parse(fileString);

        if (jsonObj.CrossSectionProcess?.length) {
          jsonObj.CrossSectionProcess.forEach((obj) => {
            if (Array.isArray(obj)) {
              obj.forEach((child) => __this.initColor(child));
            } else {
              __this.initColor(obj);
            }
          });
          __this.uploadJsonFileData = jsonObj.CrossSectionProcess;

        }
      });

    },
    initColor(obj) {
      obj.hide = false;
      if (obj.Color) {
        obj.rgba = this.hex2rgba(obj.Color);
        obj.opacity = 1;
      }
    },
    hex2rgba(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      return `rgba(${r},${g},${b},1)`;
    },
    rgba2hex(rgba) {
      let rgba_arr = rgba.replace("rgba(", "").replace(")", "").split(",");
      let r = Number(rgba_arr[0]);
      let g = Number(rgba_arr[1]);
      let b = Number(rgba_arr[2]);
      let a = Number(rgba_arr[3]);
      const toHex = (num) => {
        const hex = num.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };
      let result = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      return { hex: result, alpha: a };
    },
    //隐藏所有
    hideAll(column, event) {

      if (column.label == "隐藏") {
        this.hideAllObjs = !this.hideAllObjs;
        this.structureList.forEach((obj) => {
          obj.hide = this.hideAllObjs;
        });
      }
      this.hideClick();
    },
    //加载工艺文件
    loadCraftFile() {
      if (this.fileList.length) {

        if (this.fileList[0].type !== "application/json") {
          this.$message.error(
            this.$t("messages.view3D.ui.process_file_type_error")
          );
          return;
        }
      }
      this.loadFileDialogVisible = false;
      if (!this.uploadJsonFileData.length) {
        return;
      }
      this.loading = true;
      this.fileList = [];
      setTimeout(() => {
        this.structureData = this.uploadJsonFileData;
        this.structureList = this.removeDupStructure(); //去重

        this.updateStepList();
        this.currentStepNumber = this.structureData.length;
        this.layout.clear();
        this.layout.loadCrossSectionProcess(this.structureData);
        this.layout.procedureModelGenerate(this.currentStepNumber);
        this.scaleYChange();
        this.loading = false;
        this.cutBoxChange();
      }, 500);
    },
    removeDupStructure() {
      let res = [];

      this.structureData.forEach((obj) => {
        if (Array.isArray(obj)) {
          obj.forEach((child) => {
            if (
              !res.filter(
                (item) =>
                  item.LayerNum !== null &&
                  item.LayerNum == child.LayerNum &&
                  item.MaterialLabel == child.MaterialLabel
              ).length
            ) {
              res.push(child);
            }
          });
        } else {
          if (
            !res.filter(
              (item) =>
                item.LayerNum !== null &&
                item.LayerNum == obj.LayerNum &&
                item.MaterialLabel == obj.MaterialLabel
            ).length
          ) {
            res.push(obj);
          }
        }
      });
      res = res.filter((obj) => obj.Step !== "etch");
      return res;
    },
    //生成工艺步骤
    updateStepList() {
      let stepMap = {
        deposit: "沉积",
        etch: "刻蚀",
      };
      let stepMapEn = {
        deposit: "Deposition",
        etch: "Etching",
      };
      this.stepList = [];
      this.structureData.forEach((data, index) => {
        let obj = {
          index: index + 1,
        };
        if (Array.isArray(data)) {
          let t = "";
          let t_en = "";
          let last = data.length - 1;
          obj.step = stepMap[data[0].Step];
          obj.step_en = stepMapEn[data[0].Step];
          data.forEach((subData, index) => {
            t += this.getStepText(subData);
            obj.text = t;

            t_en += this.getStepTextEn(subData);
            obj.text_en = t_en;
            if (index !== last) {
              t += ", ";
              t_en += ", ";
            }
          });
        } else {
          obj.step = stepMap[data.Step];
          obj.text = this.getStepText(data);
          obj.text_en = this.getStepTextEn(data);
        }
        this.stepList.push(obj);
      });
    },
    getStepText(data) {
      return `图层:${data.Layer == null ? "无" : data.Layer}, 图层编号:${
        data.LayerNum == null ? "无" : data.LayerNum
      }, 材料:${data.Material}, 结构层名称:${data.MaterialLabel}, 角度:${
        data.AngleDegree == null ? "无" : data.AngleDegree
      }, 深度:${data.Depth == null ? "无" : data.Depth}, 颜色:${
        data.Color == null ? "无" : data.Color
      }`;
    },
    getStepTextEn(data) {
      return `Layer:${data.Layer == null ? "null" : data.Layer}, Layer Number:${
        data.LayerNum == null ? "null" : data.LayerNum
      }, Material:${data.Material}, Structure Name:${
        data.MaterialLabel
      }, Angle:${data.AngleDegree == null ? "null" : data.AngleDegree}, Depth:${
        data.Depth == null ? "null" : data.Depth
      }, Color:${data.Color == null ? "null" : data.Color}`;
    },
    hideRight() {
      this.showRight = !this.showRight;
      this.layout.renderer.domElement.width = 0;
      this.layout.renderer.domElement.height = 0;
      this.layout.renderer.domElement.style.width = 0;
      this.layout.renderer.domElement.style.height = 0;
      setTimeout(() => this.layout.updateViewWidth());
    },
    //显示切割盒子
    showCutBoxChange() {
      this.layout.hideCutBox(this.showCutBox);
    },
    //切割盒子数据修改
    cutBoxChange() {

      this.layout.configParams.width = this.cutBoxParam.width;
      this.layout.configParams.height = this.cutBoxParam.height;
      this.layout.configParams.depth = this.cutBoxParam.depth;
      this.layout.configParams.x = this.cutBoxParam.x;
      this.layout.configParams.y = this.cutBoxParam.y;
      this.layout.configParams.z = this.cutBoxParam.z;
      this.layout.generateCutBox(this.layout.configParams);
      this.showCutBoxChange();
    },
    //光照参数修改
    lightChange() {
      this.layout.ambientLight.intensity = this.lightParam.ambientStrength;
      this.layout.directionalLight.intensity = this.lightParam.directStrength;
      let deltaAngle =
        this.lightParam.directAngle - this.lightParam.directAngleBk;
      this.rotateAboutPoint(
        this.layout.directionalLightTarget,
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 1, 0),
        (deltaAngle * Math.PI) / 180
      );
      this.lightParam.directAngleBk = this.lightParam.directAngle;
    },
    rotateAboutPoint(obj, point, axis, theta, pointIsWorld = true) {

      pointIsWorld = pointIsWorld === undefined ? false : pointIsWorld;
      if (pointIsWorld) {
        obj.parent.localToWorld(obj.position); // compensate for world coordinate
      }
      obj.position.sub(point); // remove the offset
      obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
      obj.position.add(point); // re-add the offset
      if (pointIsWorld) {
        obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
      }
      obj.rotateOnAxis(axis, theta); // rotate the OBJECT
    },
    //模型厚度修改
    scaleYChange() {
      if (this.layout) {
        this.layout.scaleChange(this.cutBoxParam.scaleY);
      }
    },
    //工艺步骤切换
    stepChange(number) {
      if (this.currentStepNumber !== number) {
        this.currentStepNumber = number;
        this.layout.resetModel();
        this.layout.procedureModelGenerate(this.currentStepNumber);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
  },
};
</script>
<style lang="less" scoped>
#container {
  width: 100%;
  height: 100%;
}
.main-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  flex-direction: row;
}
.left-content {
  width: 252px;
  font-size: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  span {
    height: 100%;
    display: inline-block;
    margin-left: 9px;
  }
  .step-container {
    padding: 10px;
  }
  .step-head-container {
    display: flex;
    font: 14px;
  }
  .step-number {
    width: 18px;
    height: 18px;
    background: #378ddd;
    text-align: center;
    border-radius: 9px;
    color: white;
  }
  .step-active {
    background: #dce6f8;
  }
}
.right-content {
  padding-bottom: 20px;
  min-width: 252px;
  font-size: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background: white;
  span {
    height: 100%;
    display: inline-block;
    margin-left: 9px;
  }
  #structure-table {
    max-height: 800px;
    overflow: scroll;
  }
  #structure-table thead {
    color: #909399;
    font-weight: 500;
    height: 100px;
    overflow: scroll;
  }
  /deep/ #structure-table .cell {
    font-size: 12px !important;
    font-weight: 400;
    color: #666666;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: pre;
  }
}
/deep/ .el-table th.el-table__cell {
  padding: 0px;
}
/deep/.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding-top: 6px;
  padding-bottom: 6px;
}
.fillColor__item {
  width: 16px;
  height: 16px;
  margin: 1px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.hide-icon {
  height: 18px;
  padding-left: 5px;
  cursor: pointer;
}
.cardHeader {
  height: 34px;
  line-height: 34px;
  background: #f4f4f4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cardHeader strong {
  height: 100%;
  display: inline-block;
  margin-left: 16px;
}
.step-card {
  width: 100%;
  height: 86px;
  cursor: pointer;
}
.step-card:hover {
  background: #dce6f8;
}
.step-content {
  padding: 10px;
  padding-top: 0;
}
.step-text-content {
  padding-left: 25px;
  overflow: hidden;
  height: 64px;
}
.arrow {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}
.cutControl {
  width: 100%;
  height: 319px;
}
.scaleYControl {
  width: 100%;
  height: 44px;
  background: white;
}
.slider-container {
  display: flex;
  align-items: center;
}
.slider-title {
  margin: 0px;
  line-height: 32px;
  padding-left: 16px;
  white-space: pre;
}
.slider-light {
  min-width: 60px;
}
/deep/ .el-slider--with-input {
  width: 100%;
  padding-left: 30px;
  padding-right: 10px;
}
/deep/.el-slider__runway.show-input {
  margin-right: 80px;
}
/deep/ .el-input-number__decrease {
  display: none;
}
/deep/ .el-input-number__increase {
  display: none;
}
/deep/ .el-slider__input {
  width: 70px;
}
/deep/ .el-slider__button {
  width: 10px;
  height: 10px;
}
/deep/ .el-input-number .el-input__inner {
  padding: 0;
}
.dialog-button,
.el-button--primary {
  padding: 8px 25px;
  font-size: 14px;
}
.el-button--primary {
  background-color: #4385f2;
  border-color: #4385f2;
}
.choose-file-button {
  color: #333333;
  background-color: #f4f4f4;
  border-color: #dddddd;
}
.dialog-button-cancel {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
  color: #ffffff;
}
.container-3d {
  width: 100%;
  height: 100%;
  min-width: 0px;
}
.rightMenu {
  display: flex;
  flex-direction: column;
  width: 24px;
  text-align: center;
  background: #f2f5f8;
  border: 1px solid #dddddd;
  align-items: center;
  position: relative;
  .readOnlyMask {
    position: absolute;
    width: 26px;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .rightMenu__item {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-left: none;
    padding: 20px 3px;
    cursor: pointer;
    color: #333333;
    font-size: 14px;
    writing-mode: vertical-lr;
    position: relative;
  }
  .active {
    background: #0c7de6;
    color: #ffffff;
  }
}
.active {
  background: #0c7de6;
  color: #ffffff;
}
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
}
#rightDragBtn {
  width: 22px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: -14px;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
}
#rightDragBtn img {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0;
  left: -15px;
  pointer-events: auto;
}
.loadCraftButton {
  min-width: 125px;
  height: 26px;
  font-size: 12px;
  padding-left: 16px;
  padding-top: 7px;
  border: 1px solid #0c7de6;
  color: #0c7de6;
  .icon-gongyi {
    margin: 0;
    font-size: 0;
    line-height: 0px;
  }
  .icon-gongyi:before {
    font-size: 15px;
  }
  .gongyi-text {
    line-height: 7px;
  }
}
/deep/.mask {
  opacity: 0.5;
  .is-scrolling-none {
    display: none;
  }
}
</style>