import { CSG } from 'three-csg-ts'
import OctreeCSG from './OctreeCSG/OctreeCSG.js'
import { LoopSubdivision } from 'three-subdivide'
import { distanceToLineSegment } from '../graphic_f32/point-to-line-segment'
import { result } from 'underscore'
const QGeosJs = require('./geos')
const TAN41 = Math.tan((41 * Math.PI) / 180)
export let WORK_PROCEDURE = [
  {
    id: 0,
    name: '沉积',
    layerName: '',
    layerNumber: '',
    structurName: 'Si',
    structurMaterial: 'Si',
    materialColor: '#000000',
    procedureColor: '#000000',
    angle: 0,
    depth: 500,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 1,
    name: '沉积',
    layerName: '',
    layerNumber: '',
    structurName: 'Al-1',
    structurMaterial: 'Al',
    materialColor: '#9ef532',
    procedureColor: '#9ef532',
    angle: 0,
    depth: 100,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 2,
    name: '干法刻蚀',
    layerName: 'Metal1',
    layerNumber: '1',
    structurName: 'Al-1',
    structurMaterial: 'Al',
    materialColor: '',
    procedureColor: '',
    angle: 0,
    depth: 100,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 3,
    name: '沉积',
    layerName: 'Junction',
    layerNumber: '11',
    structurName: 'Al-2',
    structurMaterial: 'Al',
    materialColor: '#00FF00',
    procedureColor: '#3399FF',
    procedureColor2: '#ee2b2b',
    angle: 0,
    depth: 26,
    depth2: 26,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 4,
    name: '沉积',
    layerName: 'Junction',
    layerNumber: '11',
    structurName: 'Al-3',
    structurMaterial: 'Al',
    materialColor: '#00FF00',
    procedureColor: '#FF6600',
    procedureColor2: '#ee2b2b',
    angle: 0,
    depth: 69,
    depth2: 26 + 69,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 5,
    name: '沉积',
    layerName: 'Metal3',
    layerNumber: '3',
    structurName: 'Al-4',
    structurMaterial: 'Al',
    materialColor: '#00FF00',
    procedureColor: '#483de6',
    angle: 0,
    depth: 435,
    生成: false,
    透明度: 1.0,
  },
  {
    id: 6,
    name: '沉积',
    layerName: 'Metal4',
    layerNumber: '5',
    structurName: 'Al-5',
    structurMaterial: 'Al',
    materialColor: '#00FF00',
    procedureColor: '#9b279b',
    angle: 42,
    depth: 635,
    生成: false,
    透明度: 1.0,
  },
]
let factor = 0
let units = 1
export function generateMaterial(color) {
  let mat = new THREE.MeshLambertMaterial({ color: color })
  // let mat = new THREE.MeshPhongMaterial({
  //   color: color,
  //   shininess: 40,
  //   specular: 0x222222,
  // })
  mat.depthTest = true
  //   mat.side = 0
  //   mat.shadowSide = THREE.BackSide
  mat.transparent = true //是否透明
  mat.opacity = 1.0 //透明度
  // mat.side = THREE.DoubleSide
  //   mat.wireframe = true

  // mat.polygonOffset = true
  // mat.polygonOffsetFactor = factor
  // mat.polygonOffsetUnits = units
  // mat.shading = THREE.SmoothShading
  // mat.renderOrder = 2
  // factor += 0.01
  // units += 2
  return mat
}

export function generateCellMesh(cell) {
  let polygons = cell.get_polygons()

  let group = new THREE.Group()
  for (let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i]
    let mesh1 = generateExtrudeMesh(polygon, 0, 8, 0x049ef4)
    group.add(mesh1)
  }
  group.rotation.x = -Math.PI / 2
  return group
}
export function generateExtrudeMesh(polygon, angle = 0, depth = 0, material, offsetY = 0, scale = 1) {
  depth = depth / 1000
  offsetY = offsetY / 1000
  let shape = new THREE.Shape()
  let points = polygon.get_points()
  points.forEach((p, i) => (i ? shape.lineTo(p[0] * scale, p[1] * scale) : shape.moveTo(p[0] * scale, p[1] * scale)))
  let extrudeSettings = { steps: 1, depth: depth, bevelEnabled: false }
  let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings)
  if (angle !== 0 && angle !== null) {
    alert(angle)
    let aabb = polygon.bounding_box()
    let w = aabb[1][0] - aabb[0][0]
    let h = aabb[1][1] - aabb[0][1]
    let center = [aabb[0][0] + w / 2, aabb[0][1] + h / 2]
    angleExtrudeMesh(geometry, center, calScaleByExtrudeAngle(angle, depth, w), depth)
  }
  let mesh = new THREE.Mesh(geometry, material)
  mesh.position.z = offsetY
  mesh.updateMatrix()
  //   mesh.geometry.applyMatrix4(mesh.matrix)
  //   mesh.rotation.x = -Math.PI / 2
  //   const box = new THREE.Mesh(new THREE.BoxGeometry(50, 50, 50), new THREE.MeshNormalMaterial())
  //   box.position.y = 25
  //   box.updateMatrix()
  //   let res = CSG.subtract(mesh, box)
  return mesh
}

export function getRectanglePoints(aabb, expand = 0) {
  // let w = aabb[1][0] - aabb[0][0]
  // let h = aabb[1][1] - aabb[0][1]
  let minx = aabb[0][0] - expand
  let miny = aabb[0][1] - expand
  let maxx = aabb[1][0] + expand
  let maxy = aabb[1][1] + expand
  let res = [
    [minx, miny],
    [minx, maxy],
    [maxx, maxy],
    [maxx, miny],
  ]

  return res
}

//切分AL材料 3D
export function dividRectMesh(aabb, divid = 50, PROCEDURE, offsetY = 0) {
  let minx = aabb[0][0]
  let miny = aabb[0][1]
  let maxx = aabb[1][0]
  let maxy = aabb[1][1]
  let w = aabb[1][0] - aabb[0][0]
  let h = aabb[1][1] - aabb[0][1]
  let item_w = w / divid
  let item_h = h / divid
  let res = []
  for (let i = 0; i < divid; i++) {
    for (let j = 0; j < divid; j++) {
      let leftBottom = [minx + i * item_w, miny + j * item_h]
      let rightTop = [minx + (i + 1) * item_w, miny + (j + 1) * item_h]
      let aabb = [leftBottom, rightTop]
      let points = getRectanglePoints(aabb)
      let box = new QGdstk.Polygon(points)

      let mesh = generateExtrudeMesh(box, 0, PROCEDURE.depth, PROCEDURE.material, offsetY)
      mesh.aabb = aabb
      res.push(mesh)
    }
  }
  return res
}

//切分AL材料 2D
export function dividRectPolygon(aabb, divid = 50) {
  let minx = aabb[0][0]
  let miny = aabb[0][1]
  let maxx = aabb[1][0]
  let maxy = aabb[1][1]
  let w = aabb[1][0] - aabb[0][0]
  let h = aabb[1][1] - aabb[0][1]
  let item_w = w / divid
  let item_h = h / divid
  let res = []
  for (let i = 0; i < divid; i++) {
    for (let j = 0; j < divid; j++) {
      let leftBottom = [minx + i * item_w, miny + j * item_h]
      let rightTop = [minx + (i + 1) * item_w, miny + (j + 1) * item_h]
      let aabb = [leftBottom, rightTop]
      let points = getRectanglePoints(aabb)
      let box = new QGdstk.Polygon(points)
      res.push(box)
    }
  }
  return res
}
//刻蚀
export function subtractEtchingMesh(dividMeshs, quadTree) {
  let res = []
  let size = dividMeshs.length
  for (let i = 0; i < size; i++) {
    // if (i > 0) break
    let brick = dividMeshs[i].clone()
    let hits = quadTree.queryByAABB(dividMeshs[i].aabb, 1)


    for (let j = 0; j < hits.length; j++) {
      //   brick = CSG.subtract(brick, hits[j])
      brick = OctreeCSG.meshSubtract(brick, hits[j])
    }
    brick.material = dividMeshs[i].material
    if (brick.geometry.attributes.position.count) {
      res.push(brick)
    }
  }
  return res
}

//刻蚀2D
export function subtractEtchingPolygon(dividPolygons, quadTree, PROCEDURE, layerNum, offsetY) {

  let res = []
  let size = dividPolygons.length
  for (let i = 0; i < size; i++) {
    // if (i > 0) break
    let brick = dividPolygons[i]
    let hits = quadTree.queryPolygonsByAABB(brick.bounding_box(), layerNum)


    if (hits.length) {
      let result = new QGdstk.boolean(copyGdsPolygon(brick), copyGdsPolygons(hits), 'not')

      for (let j = 0; j < result.length; j++) {
        let mesh = generateExtrudeMesh(result[j], 0, PROCEDURE.depth, PROCEDURE.material, offsetY)
        res.push(mesh)
      }
    } else {
      let mesh = generateExtrudeMesh(brick, 0, PROCEDURE.depth, PROCEDURE.material, offsetY)
      res.push(mesh)
    }
  }
  return res
}
export function generateTargetLayerMesh(group, polygons, PROCEDURE, offsetY, scaleDepth) {

  let depth = PROCEDURE.depth
  if (scaleDepth) {
    depth = scaleDepth
  }
  let material = PROCEDURE.material

  for (let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i]
    let mesh = generateExtrudeMesh(polygon, 0, depth, material, offsetY)
    mesh.depth = depth
    mesh.geometry.computeBoundingBox()
    group.add(mesh)
  }
}

export function detectHeightSpearteModel(group, polygon, PROCEDURE, offsetY, quadTree, layers, index) {
  let depth = PROCEDURE.depth
  let material = PROCEDURE.material
  let layer = layers[index]
  let wp = quadTree.getWorkProcedureByLayer(layer)
  let hits = quadTree.queryPolygonsByAABB(polygon.bounding_box(), layer)
  if (layer && hits.length) {
    let res = new QGdstk.boolean([copyGdsPolygon(polygon)], [copyGdsPolygon(hits[0])], 'and') //和底部相交部分
    if (res.length) {

      let over_depth = wp.depth

      // if (over_depth == 26 && index == 0) {
      //   over_depth = 26 + 69
      // }
      index++
      let hitpart_mesh = generateExtrudeMesh(res[0], 0, depth, material, offsetY + over_depth)
      group.add(hitpart_mesh)
      let notcross_parts = new QGdstk.boolean([copyGdsPolygon(polygon)], [copyGdsPolygon(hits[0])], 'not') //和底部不相交部分
      notcross_parts.forEach(part => {
        // let notcross_mesh = generateExtrudeMesh(part, 0, depth, material, offsetY)
        // group.add(notcross_mesh)
        detectHeightSpearteModel(group, part, PROCEDURE, offsetY, quadTree, layers, index)
      })
    }
  } else {
    let mesh = generateExtrudeMesh(polygon, 0, depth, material, offsetY)
    group.add(mesh)
  }
}

//沉积模型
export function depositMesh(octTree, baseDepth = 0, targetGroup, bottomGroups, material, ex_depth = 0, index = 0) {
  // alert(index)

  let groupTemp = new THREE.Group()
  groupTemp.layerNumber = targetGroup.layerNumber
  groupTemp.structurMaterial = targetGroup.structurMaterial
  groupTemp.renderOrder = targetGroup.renderOrder
  groupTemp.castShadow = true
  groupTemp.receiveShadow = true
  for (let i = 0; i < targetGroup.children.length; i++) {
    let targetMesh = targetGroup.children[i].clone(true)
    targetMesh.depth = targetGroup.children[i].depth
    const group = bottomGroups[index] //底部模型
    if (group.children.length) {
      let breaks = cutMeshByGroup(octTree, targetMesh, group, baseDepth, material, ex_depth)
      if (breaks.length > 1) {
        breaks.forEach(data => {
          groupTemp.add(data)
        })
      } else {
        groupTemp.add(targetMesh)
      }
      // group.children.forEach(bottomMesh => {
      //   let breaks = isIntersect(targetMesh, bottomMesh, baseDepth, material)
      //   breaks.forEach(data => {
      //     groupTemp.add(data)
      //   })
      // })
    } else {
      groupTemp.add(targetMesh)
    }
  }
  index += 1
  if (bottomGroups[index]) {
    groupTemp = depositMesh(octTree, baseDepth, groupTemp, bottomGroups, material, ex_depth, index)
  }

  groupTemp.children = groupTemp.children.filter(mesh => {
    mesh.geometry.computeBoundingBox()
    mesh.box = new THREE.Box3().setFromObject(mesh)
    mesh.layerNumber = groupTemp.layerNumber
    mesh.structurMaterial = groupTemp.structurMaterial
    return mesh.geometry.attributes.position.count
  }) //计算包围盒 过滤空模型
  return groupTemp
}

function cutMeshByGroup(octTree, targetMesh, bottomGroup, baseDepth, material, ex_depth, excludeMesh = null) {
  let res = []
  if (!targetMesh.box) {
    targetMesh.box = new THREE.Box3().setFromObject(targetMesh)
  }
  let bottomGroupHits = octTree.queryByBox(targetMesh.box, bottomGroup.layerNumber) //四叉树搜索
  // let bottomGroupHits = bottomGroup.children
  bottomGroupHits.forEach(bottomMesh => {
    if (bottomMesh !== excludeMesh) {
      let breaks = isIntersect(targetMesh, bottomMesh, baseDepth, material, ex_depth)
      // alert(`分解${breaks.length}`)
      //存在相交分解
      if (breaks.length) {
        let not_insert_part = breaks[0] //不相交部分
        let sub_res = cutMeshByGroup(octTree, not_insert_part, bottomGroup, baseDepth, material, ex_depth, bottomMesh)
        if (sub_res.length > 1) {
          res.push(...sub_res, breaks[1])
        } else {
          res.push(...breaks)
        }
      } else {
        // res.push(targetMesh)
      }
    }
  })

  return res
}

function isIntersect(mesh1, mesh2, baseDepth, material, ex_depth = 0) {
  // let bbox1 = new THREE.Box3().setFromObject(mesh1, true)
  // let bbox2 = new THREE.Box3().setFromObject(mesh2, true)

  let res = []
  let meshIntersect = OctreeCSG.meshIntersect(mesh1, mesh2) //相交
  // let meshIntersect = CSG.intersect(mesh1, mesh2) //相交
  let isInterset = meshIntersect.geometry.attributes.position.count //存在相交
  if (isInterset) {
    let pullUp = getPullUp(mesh1, mesh2, meshIntersect) //模型向上平移高度
    pullUp = Number(pullUp.toFixed(4))

    // alert(pullUp)
    // let meshSubtract = OctreeCSG.meshSubtract(mesh1, meshIntersect_copy) //减去相交部分
    let meshSubtract = CSG.subtract(mesh1, meshIntersect) //减去相交部分

    // let pullUp = mesh2.depth
    // if (mesh1.depth > mesh2.depth) {
    //   pullUp = mesh1.depth
    // }
    // meshIntersect.position.z = pullUp + ex_depth
    meshIntersect.position.set(0, 0, pullUp)

    meshIntersect.updateMatrix()
    meshIntersect.depth = mesh1.depth
    meshIntersect.material = material
    meshSubtract.depth = mesh1.depth
    meshSubtract.material = material
    meshSubtract.geometry.computeBoundingBox()
    meshIntersect.geometry.computeBoundingBox()
    res.push(meshSubtract, meshIntersect)
  }
  //  else {
  //   res.push(mesh1)
  // }
  return res
}

function getPullUp(mesh1, mesh2, insert) {
  let tb1 = getMeshTopBottom(mesh1)
  let tb2 = getMeshTopBottom(mesh2)
  let tb3 = getMeshTopBottom(insert)
  let t1 = tb1[0]
  let b1 = tb1[1]
  let t2 = tb2[0]
  let b2 = tb2[1]
  let t3 = tb3[0]
  let b3 = tb3[1]
  let depth1 = t1 - b1
  let depth2 = t2 - b2
  let maxT = Math.max(t1, t2)
  let minB = Math.min(b1, b2)
  let maxD = Math.max(depth1, depth2)

  // if (t1 > t2) {
  // alert(maxT)

  // return depth1
  // }
  // return maxT - minB
  // alert(maxT - b3)
  return maxT - b3
}

function getMeshTopBottom(mesh) {
  let box = new THREE.Box3().setFromObject(mesh, true)
  // var geometry = mesh.geometry
  // geometry.computeBoundingBox()
  return [box.max.z, box.min.z]
}

function getCenterPoint(mesh) {
  var middle = new THREE.Vector3()
  var geometry = mesh.geometry

  geometry.computeBoundingBox()

  middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2
  middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2
  middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2

  return middle
}

export function depositPolygons(polys1, polys2) {
  return [new QGdstk.boolean(polys1, polys2, 'and'), new QGdstk.boolean(polys1, polys2, 'not')]
}

export function generateAirBridgePlanerMesh(group, polygons, PROCEDURE, offsetY, airBridgeColumLayer, quadTree) {

  for (let i = 0; i < polygons.length; i++) {

    let polygon = polygons[i]
    let aabb = polygon.bounding_box()
    let center = get2DCenterPos(aabb) //桥身中心
    let scaleCenters = []
    let intersectingLines = []
    let hits = quadTree.queryPolygonsByAABB(aabb, airBridgeColumLayer) //搜索桥墩
    if (hits.length > 2) {
      hits = getMinDistPiers(hits, center)
    }
    hits.forEach(hit => {
      // let points = polygon.get_points()
      // points.push(points[0])
      // let polyg = new QGeosJs('Polygon', [points])
      // let line = getIntersectingLine(polyg, hit.get_points())
      // if (line) {
      //   intersectingLines.push(line)
      // }
      let res = getMinDistLine(hit.get_points(), center)
      intersectingLines.push(res.line)
      scaleCenters.push(res.center)
    })


    if (hits.length == 2 && intersectingLines.length == 2) {
      const bondedParts = []
      //   const centers = []
      //将桥身拆解为两个桥墩粘合处和中间拱形部分
      hits.forEach((hit, i) => {


        // const parts = new QGdstk.boolean([polygon], [hit], 'and')
        const parts = new QGdstk.boolean([polygon], [copyGdsPolygon(hit, 100, scaleCenters[i])], 'and')

        bondedParts.push(generateExtrudeMesh(parts[0], 0, PROCEDURE.depth, PROCEDURE.material, 0, 1))
      })
      //桥拱起部分
      // const result = new QGdstk.boolean([polygon], hits, 'not')
      const result = new QGdstk.boolean([polygon], [copyGdsPolygon(hits[0], 100, scaleCenters[0]), copyGdsPolygon(hits[1], 100, scaleCenters[1])], 'not')
      let arch_body = result[0]
      let arch_mesh = generateExtrudeMesh(arch_body, 0, PROCEDURE.depth, PROCEDURE.material, 0, 1) //拱形网格
      const params = {
        split: false,
        uvSmooth: false,
        preserveEdges: false,
        flatOnly: true,
        maxTriangles: 250000,
      }
      let smooth = LoopSubdivision.modify(arch_mesh.geometry, 3, params)
      arch_mesh.geometry.dispose()
      //   let center = get2DCenterPos(arch_body.bounding_box())
      let centerLine = getCenterLine(intersectingLines) //拱形中线，最高处
      let bridge_dist = distanceToLineSegment(intersectingLines[1][0][0], intersectingLines[1][0][1], intersectingLines[1][1][0], intersectingLines[1][1][1], intersectingLines[0][0][0], intersectingLines[0][0][1])

      bendingAirBridgeBody(smooth, centerLine, bridge_dist)
      arch_mesh.geometry = smooth
      bondedParts.push(arch_mesh)
      let combine_mesh = mergeMesh(bondedParts)
      //   combine_mesh.scale.set(1000, 1000, 1000)
      combine_mesh.position.z = offsetY / 1000
      combine_mesh.updateMatrix()
      group.add(combine_mesh)
    } else {
      let mesh = generateExtrudeMesh(polygon, 0, PROCEDURE.depth, PROCEDURE.material, offsetY)
      group.add(mesh)
    }
  }
}

function copyGdsPolygon(polygon, scale, center) {
  let res = new QGdstk.Polygon(polygon.get_points())
  if (scale && center) {
    res.scale(scale, scale, center)
  }
  return res
}

function copyGdsPolygons(polygons) {
  let res = []
  for (let i = 0; i < polygons.length; i++) {
    res.push(new QGdstk.Polygon(polygons[i].get_points()))
  }
  return res
}

export function cutGroupMesh(toGroup, fromGroup, cutBox) {
  let meshes = fromGroup.children
  let length = meshes.length
  let cut_bbox = new THREE.Box3().setFromObject(cutBox, true)

  for (let i = 0; i < length; i++) {
    // const copy = meshes[i].clone()

    // copy.geometry.applyMatrix4(copy.matrix)
    // copy.updateMatrix()
    let bbox = new THREE.Box3().setFromObject(meshes[i], true)


    if (cut_bbox.containsBox(bbox)) continue
    if (cut_bbox.intersectsBox(bbox)) {
      //let result = CSG.subtract(meshes[i], cutBox)
      let result = OctreeCSG.meshSubtract(meshes[i], cutBox)


      if (result.geometry.attributes.position.count) {
        result.material = meshes[i].material
        toGroup.add(result)
      }
    } else {
      toGroup.add(meshes[i].clone())
    }
  }
}

export function overlapCalculation(topGroup, bottomGroup, depth) {
  for (let i = 0; i < topGroup.children.length; i++) {
    let topMesh = topGroup.children[i].clone()
    let bbox1 = new THREE.Box3().setFromObject(topMesh, true)
    for (let j = 0; j < bottomGroup.children.length; j++) {
      let bottomMesh = bottomGroup.children[j]
      let bbox2 = new THREE.Box3().setFromObject(bottomMesh, true)

      if (bbox1.intersectsBox(bbox2)) {
        let intersect = OctreeCSG.meshIntersect(topMesh, bottomMesh)
        // let subtract = OctreeCSG.meshSubtract(topGroup.children[i], intersect)
        // subtract.position.y += depth
        // subtract.updateMatrix()
        // topGroup.children[i] = OctreeCSG.meshUnion(topMesh, subtract)
        topGroup.children[i] = OctreeCSG.meshSubtract(topGroup.children[i], bottomMesh)
        // topMesh = topGroup.children[i]
      }
    }
  }
}
//弯曲桥身
function bendingAirBridgeBody(geometry, centerLine, dist) {
  let positions = geometry.attributes.position.array
  let ptCout = positions.length / 3
  let d = dist / 2 //桥身一半的距离
  let h = d * TAN41 //最大高度
  let half_h = h / 2
  for (let i = 0; i < ptCout; i++) {
    let x = positions[i * 3]
    let y = positions[i * 3 + 1]
    let distToCenter = distanceToLineSegment(centerLine[0][0], centerLine[0][1], centerLine[1][0], centerLine[1][1], x, y) //点到桥中线距离
    let distToSide = d - distToCenter
    positions[i * 3 + 2] += TAN41 * distToSide - half_h * (distToSide / d) * (distToSide / d) //Math.sin(distToCenter - dist)
  }
}

function calScaleByExtrudeAngle(angle, h, w) {
  let rotate = ((90 - angle) * Math.PI) / 180
  if ((w * h) / 2 > Math.tan(rotate)) {

  }



  return 1 - (2 * Math.tan(rotate) * h) / w
}

function angleExtrudeMesh(geometry, center, scale, depth) {
  let positions = geometry.attributes.position.array
  let ptCout = positions.length / 3
  for (let i = 0; i < ptCout; i++) {
    if (positions[i * 3 + 2] == depth) {
      positions[i * 3] = (positions[i * 3] - center[0]) * scale + center[0]
      positions[i * 3 + 1] = (positions[i * 3 + 1] - center[1]) * scale + center[1]
    }
  }
  // for (var i = 0; i < mesh.geometry.vertices.length; i++) {
  //     if (mesh.geometry.vertices[i].z == width/2.0) {
  //     var updateX = mesh.geometry.vertices[i].x * Math.cos(helixAngle)
  //                 - mesh.geometry.vertices[i].y * Math.sin(helixAngle);
  //     var updateY = mesh.geometry.vertices[i].y * Math.cos(helixAngle)
  //                 + mesh.geometry.vertices[i].x * Math.sin(helixAngle);
  //     mesh.geometry.vertices[i].x = updateX;
  //     mesh.geometry.vertices[i].y = updateY;
  //     }
  // }
  //     return mesh;
}

export class CellQuadTree {
  constructor(cell, polygons, WORK_PROCEDURE) {
    this.cell = cell
    this.polygons = polygons
    this.WORK_PROCEDURE = WORK_PROCEDURE
    this.#initial_quadtree()
  }

  #bounding_box_to_quadtreebox(bounding_box) {
    var top = bounding_box[1][1]
    var left = bounding_box[0][0]
    var width = Math.abs(bounding_box[1][0] - bounding_box[0][0])
    var height = Math.abs(bounding_box[1][1] - bounding_box[0][1])
    // for origin point (0,0) is most left top point, y aix is reverse to cartesian coord
    var box = new QGdstk.QuadtreeBox(left, -top, width, height)
    return box
  }

  #path_bounding_box(path) {
    var points = path.spine()
    var x = []
    var y = []
    points.forEach(element => {
      x.push(element[0])
      y.push(element[1])
    })
    var x_max = Math.max(x)
    var x_min = Math.min(x)
    var y_max = Math.max(y)
    var y_min = Math.min(y)
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  #initial_quadtree() {
    var bounding_box = this.cell.bounding_box()
    var box = this.#bounding_box_to_quadtreebox(bounding_box)
    this.quadtree = new QGdstk.Quadtree(box)
    this.id_body_table = new Map()
    this.current_id = 0
    this.#process_schema(this.cell)
  }

  #process_schema(cell) {
    let length = this.polygons.length
    for (let i = 0; i < length; i++) {
      const polygon = this.polygons[i]
      this.addNode(polygon)
    }
  }

  reBuild() {
    this.#initial_quadtree()
  }

  addNode(el) {
    let aabb = el.bounding_box()
    if (!aabb) {
      return
    }
    var box = this.#bounding_box_to_quadtreebox(aabb)
    var node = new QGdstk.QuadtreeNode(this.current_id, box)
    if (!el.js_obj) el.js_obj = {}
    el.js_obj.tree_id = this.current_id
    //this.WORK_PROCEDURE[2].depth + this.WORK_PROCEDURE[0].depth
    let mesh = generateExtrudeMesh(el, 0, 1000, this.WORK_PROCEDURE[2].material, 0)
    mesh.aabb = aabb
    this.id_body_table.set(this.current_id, { obj: el, node, mesh })
    this.quadtree.add(node)
    this.current_id++
  }

  removeNode(el) {
    let node = this.id_body_table.get(el.js_obj.tree_id)?.node
    if (!node) return false
    this.id_body_table.delete(el.js_obj.tree_id)
    this.quadtree.remove(node)
    return true
  }

  updateNode(el) {
    let exist = this.removeNode(el)
    if (exist) {
      this.addNode(el)
    }
  }

  query(x, y, width, height) {
    var box = new QGdstk.QuadtreeBox(x, -y, width, height)
    var node = this.quadtree.query(box)
    var result = []
    for (let i in node) {
      result.push(this.id_body_table.get(node[i].id).obj)
    }
    return result
  }

  queryByAABB(aabb, layerNumber) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    // if (flag) {
    //   node = node.filter(n => quadtreebox.contains(n.box))
    // }
    // let rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    for (let i in node) {
      //   let item = this.id_body_table.get(node[i].id).obj
      let item = this.id_body_table.get(node[i].id)
      if (item.obj.layer == layerNumber) {
        result.push(item.mesh)
      }
    }
    return result
  }

  queryPolygonsByAABB(aabb, layerNumber) {
    var quadtreebox = this.#bounding_box_to_quadtreebox(aabb)
    var node = this.quadtree.query(quadtreebox)
    var result = []
    // if (flag) {
    //   node = node.filter(n => quadtreebox.contains(n.box))
    // }
    // let rect = new QGdstk.Polygon([aabb[0], [aabb[0][0], aabb[1][1]], aabb[1], [aabb[1][0], aabb[0][1]]])
    for (let i in node) {
      //   let item = this.id_body_table.get(node[i].id).obj
      let item = this.id_body_table.get(node[i].id)
      if (item.obj.layer == layerNumber) {
        result.push(item.obj)
      }
    }
    return result
  }

  getWorkProcedureByLayer(num) {
    for (let i = 0; i < this.WORK_PROCEDURE.length; i++) {
      const obj = this.WORK_PROCEDURE[i]
      if (Array.isArray(obj)) {
        for (let j = 0; j < obj.length; j++) {
          const sub = obj[j]
          if (sub.layerNumber == num) {
            return sub
          }
        }
      } else {
        if (obj.layerNumber == num) {
          return obj
        }
      }
    }
  }
}

export class CellOctTree {
  constructor(cell, WORK_PROCEDURE, expand) {
    this.cell = cell
    this.expand = expand
    this.WORK_PROCEDURE = WORK_PROCEDURE
    this.#initial_oct_tree()
  }

  #bounding_box_to_oct_treebox(bounding_box) {
    let minx = bounding_box.min.x
    let miny = bounding_box.min.y
    let minz = bounding_box.min.z
    let maxx = bounding_box.max.x
    let maxy = bounding_box.max.y
    let maxz = bounding_box.max.z


    var box = new QGdstk.OcttreeBox(minx, miny, minz, maxx - minx, maxy - miny, maxz - minz)
    return box
  }

  #initial_oct_tree() {
    var bounding_box = this.cell.bounding_box()
    let box3D = {
      min: {
        x: bounding_box[0][0] - this.expand,
        y: bounding_box[0][1] - this.expand,
        z: 0,
      },
      max: {
        x: bounding_box[1][0] + this.expand,
        y: bounding_box[1][1] + this.expand,
        z: 100,
      },
    }
    var box = this.#bounding_box_to_oct_treebox(box3D)
    this.octtree = new QGdstk.Octtree(box)
    this.id_body_table = new Map()
    this.current_id = 0
  }

  addNode(mesh) {
    let aabb = mesh.box
    if (!aabb) {
      return
    }
    var box = this.#bounding_box_to_oct_treebox(aabb)

    var node = new QGdstk.OcttreeNode(this.current_id, box)
    mesh.tree_id = this.current_id
    this.id_body_table.set(this.current_id, { node, mesh })
    this.octtree.add(node)
    this.current_id++
  }

  queryByBox(aabb, layerNumber) {

    var node = this.octtree.query(this.#bounding_box_to_oct_treebox(aabb))

    var result = []
    for (let i in node) {
      let item = this.id_body_table.get(node[i].id)
      if (item.mesh.layerNumber == layerNumber) {
        result.push(item.mesh)
      }
    }
    return result
  }
}

function get2DCenterPos(aabb) {
  const w = aabb[1][0] - aabb[0][0]
  const h = aabb[1][1] - aabb[0][1]
  return [aabb[0][0] + w / 2, aabb[0][1] + h / 2]
}
function pointsDist(p1, p2) {
  let x = p1[0] - p2[0]
  let y = p1[1] - p2[1]
  return Math.sqrt(x * x + y * y)
}
function getIntersectingLine(polyg, points) {
  let size = points.length
  let max = size - 1
  for (let i = 0; i < size; i++) {
    let p1 = points[i]
    let p2
    if (i == max) {
      p2 = points[0]
    } else {
      p2 = points[i + 1]
    }

    let line = new QGeosJs('LineString', [p1, p2])
    let res = polyg.intersection(line)
    if (res?.type == 'LineString') {
      return res.coordinates
    }
  }
  return null
}

function getCenterLine(lines) {
  let x1 = lines[0][0][0]
  let y1 = lines[0][0][1]
  let x2 = lines[0][1][0]
  let y2 = lines[0][1][1]
  let x3 = lines[1][0][0]
  let y3 = lines[1][0][1]
  let x4 = lines[1][1][0]
  let y4 = lines[1][1][1]
  if (Math.sign(x1 - x2) !== Math.sign(x3 - x4)) {
    ;[x3, x4] = [x4, x3]
  }
  if (Math.sign(y1 - y2) !== Math.sign(y3 - y4)) {
    ;[y4, y3] = [y3, y4]
  }

  return [
    [(x1 + x3) / 2, (y1 + y3) / 2],
    [(x2 + x4) / 2, (y2 + y4) / 2],
  ]
}

function mergeMesh(meshes) {
  let res = meshes[0]
  for (let i = 1; i < meshes.length; i++) {
    res = CSG.union(res, meshes[i])
  }
  return res
}

//获取最近的边
function getMinDistLine(points, target) {
  let res = []
  for (let i = 0; i < points.length; i++) {
    let start = points[i]
    let end = points[i + 1]
    if (end == undefined) end = points[0]
    let dist = distanceToLineSegment(start[0], start[1], end[0], end[1], target[0], target[1])
    res.push({
      line: [start, end],
      dist,
      center: [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2],
    })
  }
  if (res.length) {
    res.sort((a, b) => a.dist - b.dist)
    scaleLine(res[0])
    return res[0]
  }
}

//放大线
function scaleLine(obj) {
  let temp = new QGdstk.Polygon(obj.line)
  temp.scale(100, 100, obj.center)
  obj.line = temp.get_points()
}

//相交超过两个桥墩获取距离最近的桥墩
function getMinDistPiers(polygons, center) {
  let res = []
  polygons.forEach(polygon => {
    let obj = { polygon, dist: pointsDist(center, get2DCenterPos(polygon.bounding_box())) }
    res.push(obj)
  })
  res.sort((a, b) => a.dist - b.dist)
  return [res[0].polygon, res[1].polygon]
}
