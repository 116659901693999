const wkt = require('wkt')
// const xwkt = require('terraformer-wkt-parser')
// const { Point } = require('paper/dist/paper-core')
var QGeos = null
//
//
//
;(function () {
  /**
   * WktParse
   * @typedef {Object} WktParse
   * @property {string} type           wkt解析对象
   * @property {number[]} coordinates  坐标数组
   */

  /**
   * WktStringify
   * @typedef {string} WktStringify wkt规则的几何信息字符串
   */

  /**
   * QGeosJs Constructor
   * @class QGeosJs                   此对象需要结合qgeos.js和qgeo.wasm使用
   * @param {Rect} obj                加载qgeos.js对象，此对象为webassembly编译后的js对象
   * @param {string} [type]           几何类型：Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon
   * @param {number[]} [coordinates]  坐标数组
   */
  function QGeosJs(type, coordinates) {
    QGeos = window.QGeos
    // this.obj = QGeos
    this.geometry = null
    this.type = type
    this.coordinates = coordinates
    this._geometry()
  }

  /**
   * 内部函数，初始化obj对象
   */
  QGeosJs.prototype._geometry = function () {
    if (this.type == 'GeometryCollection') {
      return
    }
    let wkt_obj = {
      type: this.type,
      coordinates: this.coordinates,
    }
    this.geometry = new QGeos.QGeos(wkt.stringify(wkt_obj))
    // )
  }

  /**
   * 计算长度
   * @return {number}             get total length
   */
  QGeosJs.prototype.length = function () {
    return this.geometry.length()
  }

  /**
   * 计算面积;
   * @param {Rect} bounds           bounds of the node ({ x, y, width, height })
   * @return {number[]}             [xmin, ymin, xmax, ymax]
   */
  QGeosJs.prototype.area = function () {
    return this.geometry.area()
  }

  /**
   * 计算边框[[xmin, ymin], [xmax, ymax]]
   * @param {Rect} bounds           bounds of the node ({ x, y, width, height })
   * @return {number[]}             [[xmin, ymin], [xmax, ymax]]
   */
  QGeosJs.prototype.bounds = function () {
    let res = wkt.parse(this.geometry.bounds())
    let x_arr = []
    let y_arr = []
    for (let i = 0, len = res.coordinates.length; i < len; i++) {
      x_arr.push(res.coordinates[i][0])
      y_arr.push(res.coordinates[i][1])
    }
    x_min = Math.min(...x_arr)
    x_max = Math.max(...x_arr)
    y_min = Math.min(...y_arr)
    y_max = Math.max(...y_arr)
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  /**
   * 膨胀(r>0)和腐蚀(r<0);
   * @param {r}           膨胀和腐蚀宽度
   * @return {WktParse}
   */
  QGeosJs.prototype.buffer = function (r) {
    return this.getGeos(this.geometry.buffer(r))
  }

  /**
   * 两个几何物体之间的距离;
   * @param {QGeosJs} other         other geometry object
   * @return {WktParse}
   */
  QGeosJs.prototype.distance = function (other) {
    return this.geometry.distance(other.geometry.get())
  }

  /**
   * 联合两个几何物体;
   * @param {QGeosJs} other         other geometry object
   * @return {WktParse}
   */
  QGeosJs.prototype.union = function (other) {
    return this.getGeos(this.geometry.union(other.geometry.get()))
  }

  /**
   * 两个几何物体交集;
   * @param {QGeosJs} other         other geometry object
   * @return {WktParse}
   */
  QGeosJs.prototype.intersection = function (other) {
    return this.getGeos(this.geometry.intersection(other.geometry.get()))
  }

  /**
   * 两个几何物体非集;
   * @param {QGeosJs} other         other geometry object
   * @return {WktParse}
   */
  QGeosJs.prototype.difference = function (other) {
    return this.getGeos(this.geometry.difference(other.geometry.get()))
  }

  QGeosJs.prototype.getGeos = function (wkt_str) {
    let wkt_obj = wkt.parse(wkt_str)
    // let wkt_obj = xwkt.parse(wkt_str)

    if (wkt_obj !== null) {
      let geos = null
      if (wkt_obj.type === 'GeometryCollection') {
        geos = new QGeosJs(wkt_obj.type, wkt_obj.geometries)
      } else {
        geos = new QGeosJs(wkt_obj.type, wkt_obj.coordinates)
      }
      return geos
    }
    return null
  }

  //export for commonJS or browser
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = QGeosJs
  } else {
    window.QGeosJs = QGeosJs
  }
})()
