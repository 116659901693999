import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js'
import Stats from 'three/examples/jsm/libs/stats.module'
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'
// import { Octree } from 'three/src/math/Octree.js';
import { CSM } from 'three/examples/jsm/csm/CSM'
import { CSMHelper } from 'three/examples/jsm/csm/CSMHelper'
import { EffectComposer } from 'three/examples/js/postprocessing/EffectComposer'
import { CopyShader } from 'three/examples/js/shaders/CopyShader'
import { RenderPass } from 'three/examples/js/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/js/postprocessing/ShaderPass'
import { SSAARenderPass } from 'three/examples/js/postprocessing/SSAARenderPass'
import { SMAAPass } from 'three/examples/js/postprocessing/SMAAPass'
import { FXAAShader } from 'three/examples/js/shaders/FXAAShader'
import { SMAAShader } from 'three/examples/js/shaders/SMAAShader'
import {
  WORK_PROCEDURE,
  generateExtrudeMesh,
  generateCellMesh,
  generateMaterial,
  getRectanglePoints,
  CellQuadTree,
  CellOctTree,
  dividRectMesh,
  subtractEtchingMesh,
  generateTargetLayerMesh,
  generateAirBridgePlanerMesh,
  cutGroupMesh,
  overlapCalculation,
  depositMesh,
  dividRectPolygon,
  subtractEtchingPolygon,
  depositPolygons,
} from './mesh-generator'
import { CSG } from 'three-csg-ts'
const CAMERA_CONFIG = {
  CAMERA: {
    FP: {
      SPEED: 150,
    },
    TP: {
      SPEED: 5000,
    },
  },
}

export class Qeda3DLayout {
  constructor(cell) {
    /***性能监控***/
    this.stats = null
    // this.initStats()
    /***GUI***/
    this.GUI = null
    /***工艺材料***/
    // this.initMaterial()
    /***场景***/
    this.scene = null
    /***相机 控制器***/
    this.camera = null
    this.orBitControls = null //第三人称相机
    this.firstPersonControls = null //第一人称相机
    this.controls = null
    /***渲染器***/
    this.renderer = null
    /***CSM 阴影***/
    this.csm = null
    this.csmHelper = null
    this.composer = null
    this.fxaaPass = null
    /***相机点光源***/
    this.pointLigh = null
    this.ambientLight = null
    this.directionalLight = null
    this.directionalLightTarget = null
    this.WORK_PROCEDURE = []
    this.cell = cell
    this.cellPolygons = this.cell.get_polygons()
    this.quadTree = null //版图四叉树
    this.octTree = null //模型八叉数
    this.rootBox = null
    this.groups = []
    this.cutGroups = []
    this.group = new THREE.Group()
    this.clock = new THREE.Clock()
    this.expand = 10
    this.lon = 0 //相机朝向和z轴夹角
    this.lat = 0 //相机顶部朝向和y
    this.leftPress = false
    this.configParams = {
      拉伸系数: 1,
      width: 10,
      height: 10,
      depth: 10,
      x: 0,
      y: 0,
      z: 0,
      showCutBox: false,
      clickCutWay: function () {
        that.generateCutView()
      },
    }
    //剖面切除盒子
    this.cutBoxMat = new THREE.MeshStandardMaterial({ color: '#FFFFFF', side: THREE.DoubleSide, transparent: true, depthTest: true, opacity: 0.2 }) // depthWrite: false
    this.cutBoxMat.polygonOffset = true
    this.cutBoxMat.polygonOffsetFactor = 0
    this.cutBoxMat.polygonOffsetUnits = 1
    this.cutBoxGroup = new THREE.Group()
    this.cutBoxGroup.renderOrder = 1
    this.debug_box = null
    this.init()
    // this.initGUI()
    this.animate = this.drawFrame.bind(this) //绘制循环
    requestAnimationFrame(this.animate)
    this.resize = this.onWindowResize.bind(this) //缩放窗口
    this.keyBoardListener = this.keyDown.bind(this)
    this.keyUpBoardListener = this.keyUp.bind(this)
    this.mouseMoveListener = this.mouseMove.bind(this)
    this.mouseDownListener = this.mouseDown.bind(this)
    this.mouseUpListener = this.mouseUp.bind(this)
    this.addEvents()
    this.updateFpsAngle()
  }

  init() {
    let container = document.getElementById('container-3d')
    // 初始化渲染器
    this.renderer = new THREE.WebGLRenderer({ antialias: false, alpha: false, logarithmicDepthBuffer: true, powerPreference: 'high-performance' }) //
    // 渲染器的大小
    this.renderer.setSize(container.clientWidth, container.clientHeight)
    // this.renderer.setClearColor(0x494949, 1)
    // this.renderer.shadowMap.enabled = true
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap // default THREE.PCFShadowMap
    // 将渲染器添加到页面
    container.appendChild(this.renderer.domElement)
    // 添加透视相机
    this.camera = new THREE.PerspectiveCamera(60, container.clientWidth / container.clientHeight, 0.01, 100000)

    this.initOrBitControls()
    this.initFirstPersonControls()
    this.firstPersonControls.enabled = false
    this.controls = this.orBitControls
    // 相机的位置
    this.restCameraPosition()
    //场景
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x494949)
    //测试模型
    // this.camera.lookAt(new THREE.Vector3(0, 0, 0))
    // let polygon = new QGdstk.Polygon([
    //   [-5, 5],
    //   [5, 5],
    //   [5, -5],
    //   [-5, -5],
    // ])
    // let mesh1 = generateExtrudeMesh(polygon, 30, 2, 0x049ef4)
    // this.group.add(mesh1)
    // let mesh2 = generateExtrudeMesh(polygon, 45, 4, 0x049ef4)
    // mesh2.position.x = 12
    // this.group.add(mesh2)
    // let mesh3 = generateExtrudeMesh(polygon, 60, 7, 0x049ef4)
    // mesh3.position.x = 24
    // this.group.add(mesh3)
    // let mesh4 = generateExtrudeMesh(polygon, 0, 10, 0x049ef4)
    // mesh4.position.x = 36
    // this.group.add(mesh4)
    // this.group.rotation.x = -Math.PI / 2
    // this.scene.add(this.group)
    //光照
    this.ambientLight = new THREE.AmbientLight(0x222222, 3)
    this.scene.add(this.ambientLight)
    this.pointLight = new THREE.PointLight(0xffffff, 1, 100)
    // this.pointLight.castShadow = true
    // this.pointLight.shadow.mapSize.width = 1024 // default
    // this.pointLight.shadow.mapSize.height = 1024 // default
    // this.pointLight.shadow.camera.near = 0.5 // default
    // this.pointLight.shadow.camera.far = 1000 // default
    // this.scene.add(this.pointLight)
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0, 100, 0)
    let mat = new THREE.MeshPhysicalMaterial({ color: '#18b1b4' })
    const geometry1 = new THREE.BoxGeometry(10, 10, 10)
    let debug_box = new THREE.Mesh(geometry1, mat)
    this.directionalLightTarget = debug_box
    this.directionalLightTarget.translateZ(-200)
    this.scene.add(this.directionalLightTarget)
    this.directionalLightTarget.visible = false
    this.directionalLight.target = this.directionalLightTarget
    // this.directionalLight.castShadow = true // default false

    // // //Set up shadow properties for the light
    // // this.directionalLight.shadow.mapSize.width = 1024 // default
    // // this.directionalLight.shadow.mapSize.height = 1024 // default
    // // this.directionalLight.shadow.camera.near = 0.5 // default
    // // this.directionalLight.shadow.camera.far = 1000 // default
    // // this.directionalLight.shadow.bias = 0.1
    this.scene.add(this.directionalLight)
    // const helper = new THREE.DirectionalLightHelper(this.directionalLight, 50)
    // this.scene.add(helper)
    let directionalLight2 = new THREE.DirectionalLight(0x0040ff, 0.1)
    directionalLight2.position.set(0, 100, 0)
    this.scene.add(directionalLight2)
    //坐标网格
    // this.scene.add(new THREE.AxesHelper(100000000))
    // const grid = new THREE.GridHelper(10000, 10000)
    this.cutBoxGroup.rotation.x = -Math.PI / 2
    if (this.showCutBox) {
      this.scene.add(this.cutBoxGroup)
    }
    // this.csm = new CSM({
    //   fade: true,
    //   far: 1000,
    //   maxFar: 1000,
    //   mode: 'practical',
    //   cascades: 4,
    //   shadowMapSize: 2048,
    //   lightDirection: new THREE.Vector3(-1, -1, 0),
    //   camera: this.camera,
    //   parent: this.scene,
    //   lightIntensity: 1.0,
    //   lightFar: 5000,
    // })
    // this.csmHelper = new CSMHelper(this.csm)

    // this.csmHelper.visible = true
    // this.csmHelper.displayFrustum = true
    // this.csmHelper.displayPlanes = true
    // this.csmHelper.displayShadowBounds = true
    // this.scene.add(this.csmHelper)

    // this.debugShadow()

    // let mat = new THREE.MeshPhysicalMaterial({ color: '#18b1b4' })
    // const geometry1 = new THREE.BoxGeometry(10, 10, 10)
    // this.debug_box = new THREE.Mesh(geometry1, mat)
    // this.scene.add(this.debug_box)

    //抗锯齿后处理
    this.composer = new THREE.EffectComposer(this.renderer)
    const renderPass = new THREE.RenderPass(this.scene, this.camera)
    this.composer.addPass(renderPass)

    // this.composer.setPixelRatio(1) // ensure pixel ratio is always 1 for performance reasons
    // let ssaaRenderPass = new THREE.SSAARenderPass(this.scene, this.camera)
    // ssaaRenderPass.clearColor = 'black'
    // this.composer.addPass(ssaaRenderPass)
    // FXAA抗锯齿
    this.fxaaPass = new THREE.ShaderPass(THREE.FXAAShader)
    const pixelRatio = this.renderer.getPixelRatio()
    this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (this.renderer.domElement.offsetWidth * pixelRatio)
    this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (this.renderer.domElement.offsetHeight * pixelRatio)
    this.fxaaPass.renderToScreen = true
    this.composer.addPass(this.fxaaPass)

    //SMAA抗锯齿
    // const smaa_pass = new THREE.SMAAPass(window.innerWidth * this.renderer.getPixelRatio(), window.innerHeight * this.renderer.getPixelRatio())

    // this.composer.addPass(smaa_pass)
  }

  updateFpsAngle() {
    var vector = this.camera.getWorldDirection(new THREE.Vector3())
    let angle = THREE.Math.radToDeg(Math.atan2(vector.x, vector.z))
    this.lon = angle
  }

  initOrBitControls() {
    this.orBitControls = new OrbitControls(this.camera, this.renderer.domElement)
    this.orBitControls.keys = {
      LEFT: 'KeyA', //left arrow
      UP: 'KeyW', // up arrow
      RIGHT: 'KeyD', // right arrow
      BOTTOM: 'KeyS', // down arrow
    }
    this.orBitControls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.PAN,
    }
    this.orBitControls.listenToKeyEvents(window)
    this.orBitControls.screenSpacePanning = false
    // this.orBitControls.enableDamping = true //阻尼动画
    // this.orBitControls.dampingFactor = 0.05
    this.orBitControls.enabled = true
    this.orBitControls.zoomSpeed = 1
    this.orBitControls.panSpeed = 1
    this.orBitControls.rotateSpeed = 1
    this.orBitControls.minZoom = 0
    this.orBitControls.maxPolarAngle = Math.PI / 2
  }

  initFirstPersonControls() {
    this.firstPersonControls = new FirstPersonControls(this.camera, this.renderer.domElement)
    // this.firstPersonControls.lookSpeed = 0.2 //鼠标移动查看的速度
    this.firstPersonControls.movementSpeed = 20 //相机移动速度
    this.firstPersonControls.activeLook = false
    this.firstPersonControls.lookVertical = false
  }

  switchControl() {

    if (this.controls.constructor.name === 'FirstPersonControls') {
      this.firstPersonControls.enabled = false
      this.controls = this.orBitControls
    } else {
      this.orBitControls.enabled = false
      this.controls = this.firstPersonControls
      this.controls.lookAt(this.orBitControls.target)
      this.updateFpsAngle()
    }
    this.controls.update(this.clock.getDelta())
    this.controls.enabled = true
  }

  addEvents() {
    window.addEventListener('resize', this.resize)
    document.addEventListener('keydown', this.keyBoardListener)
    document.addEventListener('keyup', this.keyUpBoardListener)
    this.renderer.domElement.addEventListener('mousemove', this.mouseMoveListener)
    this.renderer.domElement.addEventListener('mouseup', this.mouseUpListener)
    this.renderer.domElement.addEventListener('mousedown', this.mouseDownListener)
  }

  delete() {
    window.removeEventListener('resize', this.resize)
    document.removeEventListener('keydown', this.keyBoardListener)
    document.removeEventListener('keyup', this.keyUpBoardListener)
    this.renderer.domElement.removeEventListener('mousemove', this.mouseMoveListener)
    this.renderer.domElement.removeEventListener('mouseup', this.mouseUpListener)
    this.renderer.domElement.removeEventListener('mousedown', this.mouseDownListener)
  }

  initStats() {
    //实例化
    this.stats = new Stats()
    //setMode参数如果是0，监测的是FPS信息，如果是1，监测的是渲染时间
    this.stats.setMode(0)
    //把统计面板放到左上角
    this.stats.domElement.style.position = 'absolute'
    this.stats.domElement.style.top = '0px'
    this.stats.domElement.style.left = '0px'
    //添加到body里
    document.body.appendChild(this.stats.domElement)
  }

  initGUI() {
    this.GUI = new GUI()

    let that = this
    // let params = {
    //   拉伸系数: 1,
    //   width: 10,
    //   height: 10,
    //   depth: 10,
    //   x: 0,
    //   y: 0,
    //   z: 0,
    //   showCutBox: true,
    //   clickCutWay: function () {
    //     that.generateCutView()
    //   },
    // }
    // let max = 10000
    // let step = 1
    // this.configParams = params
    // let config_folder = this.GUI.addFolder('全局配置')
    // config_folder
    //   .add(params, '拉伸系数', 1, max)
    //   .step(1)
    //   .onChange(v => this.scaleChange(v))
    // config_folder
    //   .add(params, 'showCutBox')
    //   .name('显示切割盒子')
    //   .onChange(v => this.displayCutBox(v))
    // config_folder
    //   .add(params, 'width', 0, max)
    //   .step(step)
    //   .name('宽')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder
    //   .add(params, 'height', 0, max)
    //   .step(step)
    //   .name('高')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder
    //   .add(params, 'depth', 0, max)
    //   .step(step)
    //   .name('厚度')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder
    //   .add(params, 'x', -max, max)
    //   .step(step)
    //   .name('X平移')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder
    //   .add(params, 'y', -max, max)
    //   .step(step)
    //   .name('Y平移')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder
    //   .add(params, 'z', -max, max)
    //   .step(step)
    //   .name('Z平移')
    //   .onChange(v => this.generateCutBox(params))
    // config_folder.add(params, 'clickCutWay').name('生成剖面')
    // config_folder.close()
    // WORK_PROCEDURE.forEach(obj => {
    //   let name = `${obj.name}-${obj.structurName}`
    //   let folder = this.GUI.addFolder(name)
    //   folder.add(obj, '生成').onChange(v => this.procedureChange(obj.id, v))
    //   folder
    //     .addColor(obj, 'procedureColor')
    //     .name('颜色')
    //     .onChange(v => {
    //       obj.material.color.set(v)
    //     })
    //   folder
    //     .add(obj, '透明度', 0, 1)
    //     .step(0.01)
    //     .onChange(v => {
    //       obj.material.opacity = v
    //     })
    //   folder.close()
    // })

    // const gui = new GUI()
    // const params = {
    //   orthographic: false,
    //   fade: false,
    //   far: 1000,
    //   mode: 'practical',
    //   lightX: -1,
    //   lightY: -1,
    //   lightZ: 0,
    //   margin: 200,
    //   lightFar: 5000,
    //   lightIntensity: 1,
    //   lightNear: 1,
    //   shadowBias: 0.000001,
    //   autoUpdateHelper: true,
    //   updateHelper: function () {
    //     that.csmHelper.update()
    //   },
    // }


    // gui.add(params, 'fade').onChange(function (value) {
    //   that.csm.fade = value
    //   that.csm.updateFrustums()
    // })

    // gui
    //   .add(params, 'far', 1, 5000)
    //   .step(1)
    //   .name('shadow far')
    //   .onChange(function (value) {
    //     that.csm.maxFar = value
    //     that.csm.updateFrustums()
    //   })

    // gui
    //   .add(params, 'shadowBias', -10, 1)
    //   .step(0.000001)
    //   .name('shadow bias')
    //   .onChange(function (value) {
    //     that.csm.shadowBias = value
    //     for (let i = 0; i < that.csm.lights.length; i++) {
    //       that.csm.lights[i].shadow.bias = value
    //     }
    //     that.csm.updateFrustums()

    //   })

    // gui
    //   .add(params, 'mode', ['uniform', 'logarithmic', 'practical'])
    //   .name('frustum split mode')
    //   .onChange(function (value) {
    //     that.csm.mode = value
    //     that.csm.updateFrustums()
    //   })

    // gui
    //   .add(params, 'lightX', -1, 1)
    //   .name('light direction x')
    //   .onChange(function (value) {
    //     that.csm.lightDirection.x = value
    //   })

    // gui
    //   .add(params, 'lightY', -1, 1)
    //   .name('light direction y')
    //   .onChange(function (value) {
    //     that.csm.lightDirection.y = value
    //   })

    // gui
    //   .add(params, 'lightZ', -1, 1)
    //   .name('light direction z')
    //   .onChange(function (value) {
    //     that.csm.lightDirection.z = value
    //   })

    // gui
    //   .add(params, 'margin', 0, 200)
    //   .name('light margin')
    //   .onChange(function (value) {
    //     that.csm.lightMargin = value
    //   })

    // gui
    //   .add(params, 'lightNear', 1, 10000)
    //   .name('light near')
    //   .onChange(function (value) {
    //     for (let i = 0; i < that.csm.lights.length; i++) {
    //       that.csm.lights[i].shadow.camera.near = value
    //       that.csm.lights[i].shadow.camera.updateProjectionMatrix()
    //     }
    //   })
    // gui
    //   .add(params, 'lightIntensity', 0, 10)
    //   .name('light intensity')
    //   .onChange(function (value) {
    //     that.csm.lightIntensity = value
    //     for (let i = 0; i < that.csm.lights.length; i++) {
    //       that.csm.lights[i].intensity = value
    //     }
    //   })

    // gui
    //   .add(params, 'lightFar', 1, 10000)
    //   .name('light far')
    //   .onChange(function (value) {
    //     for (let i = 0; i < that.csm.lights.length; i++) {
    //       that.csm.lights[i].shadow.camera.far = value
    //       that.csm.lights[i].shadow.camera.updateProjectionMatrix()
    //     }
    //   })
    // this.GUI = gui
  }

  initMaterial() {
    this.WORK_PROCEDURE.forEach(obj => {
      if (Array.isArray(obj)) {
        obj.forEach(child => {
          child.material = generateMaterial(child.procedureColor)
        })
      } else {
        obj.material = generateMaterial(obj.procedureColor)
      }
    })
  }

  //绘制循环
  drawFrame() {
    requestAnimationFrame(this.animate)
    // if (this.controls?.enabled) this.controls.update(this.clock.getDelta())
    if (this.orBitControls.enabled) {
      // this.debug_box.position = this.controls.target
      this.orBitControls.update()
    } else {
      if (this.camera.position.y <= 0) {
        this.camera.position.y = 0
      }
      this.controls.update(this.clock.getDelta())
    }
    // this.pointLight.position = this.camera.position
    this.camera.updateMatrixWorld()
    // this.csm.update()
    // this.csm.updateFrustums()
    // this.csmHelper.update()
    this.composer.render()
    // this.renderer.render(this.scene, this.camera)

    // this.stats.update()
  }

  testMesh() {
    const box_geometry = new THREE.BoxGeometry(10, 10, 10)
    const material = new THREE.MeshPhongMaterial({ color: 0x049ef4, wireframe: false, shininess: 50 })
    return new THREE.Mesh(box_geometry, material)
  }

  onWindowResize() {
    let container = document.getElementById('container-3d')
    let w = container.offsetWidth // window.innerWidth
    let h = container.offsetHeight // window.innerHeight
    // 重新设置相机宽高比例
    this.camera.aspect = w / h


    // 更新相机投影矩阵
    this.camera.updateProjectionMatrix()
    // 重新设置渲染器渲染范围
    this.renderer.setSize(w, h)

    this.composer.setSize(container.offsetWidth, container.offsetHeight)

    const pixelRatio = this.renderer.getPixelRatio()

    this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (container.offsetWidth * pixelRatio)
    this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (container.offsetHeight * pixelRatio)
  }

  keyDown(e) {
    // e.preventDefault()

    const key = e.key.toLowerCase()
    const shiftKey = e.shiftKey
    // if (!this.controlMode) {
    //   let speed = 1
    //   if (key == 'w') {

    //     this.camera.translateZ(-speed)
    //     return
    //   }
    //   if (key == 's') {
    //     this.camera.translateZ(speed)
    //     return
    //   }
    //   if (key == 'a') {
    //     this.camera.translateX(-speed)
    //     return
    //   }
    //   if (key == 'd') {
    //     this.camera.translateX(speed)
    //     return
    //   }
    // }
    if (key == 'tab') {
      e.preventDefault()
      this.switchControl()
    }
    if (this.controls.constructor.name === 'FirstPersonControls') {
      if (shiftKey) {
        this.controls.movementSpeed = CAMERA_CONFIG.CAMERA.FP.SPEED * 3
      } else {
        this.controls.movementSpeed = CAMERA_CONFIG.CAMERA.FP.SPEED
      }
    }
    if (key == 'g') {
      this.exportObj()
    }
    if (key == 'e') {
      this.restCameraPosition()
    }
  }

  keyUp(e) {}
  mouseDown(e) {
    if (e.button === 0) {
      this.leftPress = true
    }
  }

  mouseMove(e) {
    const mouseX = e.movementX || e.mozMovementX || e.webkitMovementX || 0
    const mouseY = e.movementY || e.mozMovementY || e.webkitMovementY || 0

    if (this.controls.constructor.name === 'FirstPersonControls') {
      if (this.leftPress) {
        const targetPosition = new THREE.Vector3()
        this.lon -= mouseX * 0.5 //Math.sign(mouseX)
        this.lat -= mouseY * 0.5 // * actualLookSpeed * verticalLookRatio
        this.lat = Math.max(-85, Math.min(85, this.lat))
        let phi = THREE.MathUtils.degToRad(90 - this.lat)
        const theta = THREE.MathUtils.degToRad(this.lon)
        // if (this.constrainVertical) {
        //   phi = THREE.MathUtils.mapLinear(phi, 0, Math.PI, this.verticalMin, this.verticalMax)
        // }
        const position = this.camera.position
        targetPosition.setFromSphericalCoords(1, phi, theta).add(position)
        this.controls.lookAt(targetPosition)
      }
    }
  }

  mouseUp(e) {
    if (e.button === 0) {
      this.leftPress = false
    }
  }

  //工艺步骤模型生成
  procedureChange(id, v) {

    this.procedureModelGenerate(id, v)
    this.resetCutGroups()
  }

  procedureModelGenerate(id) {


    this.clearScene()
    // this.groups[2].position.z = -0.0001
    // if (this.cell) {
    //   if (id == 0) {
    //     this.scene.add(this.groups[0])
    //   } else if (id == 1) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[1])
    //   } else if (id == 2) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[2])
    //   } else if (id == 3) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[2])
    //     this.scene.add(this.groups[3][0])
    //     this.scene.add(this.groups[3][1])
    //   } else if (id == 4) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[2])
    //     this.scene.add(this.groups[3][0])
    //     this.scene.add(this.groups[4][0])
    //     this.scene.add(this.groups[4][1])
    //   } else if (id == 5) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[2])
    //     this.scene.add(this.groups[4])
    //     this.scene.add(this.groups[5])
    //   } else if (id == 6) {
    //     this.scene.add(this.groups[0])
    //     this.scene.add(this.groups[2])
    //     this.scene.add(this.groups[4])
    //     this.scene.add(this.groups[5])
    //     this.scene.add(this.groups[6])
    //   }
    // }
    if (this.cell) {
      let index = id - 1
      this.groups.forEach((group, i) => {
        if (i <= index) {
          if (Array.isArray(group)) {
            group.forEach(subGroup => {
              this.scene.add(subGroup)
            })
          } else {
            if (i == 1 && index > 1) {
              //排除刻蚀层
            } else {
              this.scene.add(group)
            }
          }
          // this.scene.add(this.groups[0])
          // this.scene.add(this.groups[2])
          // this.scene.add(this.groups[3][0])
          // this.scene.add(this.groups[3][1])
          // this.scene.add(this.groups[4][0])
          // this.scene.add(this.groups[4][1])
          // this.scene.add(this.groups[5])
          // this.scene.add(this.groups[6])
          // this.scene.add(this.groups[7])
        }
      })

      // this.groups.forEach(group => {
      //   if (Array.isArray(group)) {
      //     group.forEach(g => this.scene.add(g))
      //   } else {
      //     this.scene.add(group)
      //   }
      // })
    }
  }

  exportObj() {
    // Instantiate an exporter
    const exporter = new OBJExporter()

    // Parse the input and generate the OBJ output
    const data = exporter.parse(this.scene)
    let mime = 'application/octet-stream'
    var download_link = document.getElementById('download_scene')
    if (download_link == null) {
      download_link = document.createElement('a')
      download_link.id = 'download_scene'
      download_link.style.display = 'none'
      document.body.appendChild(download_link)
    }
    download_link.download = 'scene.obj'
    download_link.href = URL.createObjectURL(
      new Blob([data], {
        type: mime,
      })
    )
    download_link.click()
  }

  topChange(v) {

  }

  bottomChange(v) {

  }

  generateCutBox(configParams) {

    let w = configParams.width
    let h = configParams.height
    let depth = configParams.depth
    this.cutBoxGroup.clear()
    if (w !== 0 && h !== 0 && depth !== 0) {

      const geometry = new THREE.BoxGeometry(w, h, depth)
      const box = new THREE.Mesh(geometry, this.cutBoxMat)
      // box.castShadow = true
      box.position.z += depth / 2
      box.position.x = configParams.x
      box.position.y = configParams.y
      box.position.z += configParams.z
      box.updateMatrix()
      this.scene.remove(this.cutBoxGroup)
      this.cutBoxGroup.add(box)
      this.scene.add(this.cutBoxGroup)
    }
  }
  displayCutBox(v) {
    this.cutBoxGroup.visible = v
  }
  //生成剖面
  generateCutView(id) {

    let resultGroup = this.generateGroupsByWP()
    if (this.cutBoxGroup.children.length) {
      this.clearScene()
      let cutbox = this.cutBoxGroup.children[0]
      for (let i = 0; i < resultGroup.length; i++) {
        // this.cutGroups[i].scale.set(0.001, 0.001, 0.001)
        if (Array.isArray(resultGroup[i])) {
          resultGroup[i].forEach((subGroup, j) => {
            subGroup.rotation.x = -Math.PI / 2
            cutGroupMesh(subGroup, this.cutGroups[i][j], cutbox)
          })
        } else {
          resultGroup[i].rotation.x = -Math.PI / 2
          cutGroupMesh(resultGroup[i], this.cutGroups[i], cutbox)
        }
      }
    }
    this.cutGroups = resultGroup
    if (this.cell) {
      if (this.cell) {
        let index = id - 1
        this.cutGroups.forEach((group, i) => {
          if (i <= index) {
            if (Array.isArray(group)) {
              group.forEach(subGroup => {
                this.scene.add(subGroup)
              })
            } else {
              if (i == 1 && index > 1) {
                //排除刻蚀层
              } else {
                this.scene.add(group)
              }
            }
          }
        })
      }
    }
  }

  clearScene() {
    this.groups.forEach(group => {
      if (Array.isArray(group)) {
        group.forEach(g => this.scene.remove(g))
      } else {
        this.scene.remove(group)
      }
    })
    this.cutGroups.forEach(group => {
      if (Array.isArray(group)) {
        group.forEach(g => this.scene.remove(g))
      } else {
        this.scene.remove(group)
      }
    })
  }

  deleteGroups() {
    this.groups.forEach(group => {
      if (Array.isArray(group)) {
        group.forEach(g => {
          this.deleteGroup(g)
        })
      } else {
        this.deleteGroup(group)
      }
    })
    this.cutGroups.forEach(group => {
      if (Array.isArray(group)) {
        group.forEach(g => {
          this.deleteGroup(g)
        })
      } else {
        this.deleteGroup(group)
      }
    })
  }

  deleteGroup(group) {
    group.children.forEach(obj => {
      obj.geometry.dispose()
      obj.material.dispose()
    })
  }

  loadCrossSectionProcess(data) {
    let res = []
    data.forEach((obj, index) => {
      let item = []
      if (Array.isArray(obj)) {
        obj.forEach(child => {
          item.push(this.mapWorkProcedure(child, index))
        })
      } else {
        item = this.mapWorkProcedure(obj, index)
      }

      res.push(item)
    })
    this.WORK_PROCEDURE = res
    this.initMaterial()

    this.groups = this.generateGroupsByWP()

    // this.cutGroups = this.generateGroupsByWP()
    this.buildMeshData()
    setTimeout(() => this.resetCutGroups())
    // this.procedureModelGenerate()
    // this.setGroupMeshProp()
    this.restCameraPosition()
  }

  //根据工艺流程生成group
  generateGroupsByWP() {
    return this.WORK_PROCEDURE.map(obj => {
      let group
      if (Array.isArray(obj)) {
        group = []
        obj.forEach(child => {
          let g = new THREE.Group()
          g.layerNumber = child.layerNumber
          g.structurMaterial = child.structurMaterial
          group.push(g)
        })
      } else {
        group = new THREE.Group()
        group.layerNumber = obj.layerNumber
        group.structurMaterial = obj.structurMaterial
      }

      return group
    })
  }
  mapWorkProcedure(obj, index) {
    return {
      id: index,
      name: obj.Step,
      layerName: obj.Layer,
      layerNumber: obj.LayerNum,
      structurName: obj.Material,
      structurMaterial: obj.MaterialLabel,
      materialColor: '#000000',
      procedureColor: obj.Color,
      angle: obj.AngleDegree,
      depth: obj.Depth,
      generate: !obj.hide,
      opacity: obj.opacity,
    }
  }

  //计算底座细分
  calDividSize(eatLayer) {
    //计算需要刻蚀的多边形数量顶点数量
    let etchPointsLength = 0
    let etch = this.cellPolygons.filter(polygon => {
      if (polygon.layer == eatLayer) {
        etchPointsLength += polygon.points.length
        return true
      }
    })
    let etchSize = etch.length


    if (etchSize <= 4000) {
      return 1
    } else if (etchSize < 8000) {
      return 2
    } else if (etchSize < 16000) {
      return 4
    } else if (etchSize < 32000) {
      return 16
    } else if (etchSize < 64000) {
      return 32
    } else {
      return 50
    }
    // if (etchPointsLength <= 200) {
    //   return 1
    // } else if (etchPointsLength < 40000) {
    //   return 8
    // } else if (etchPointsLength < 120000) {
    //   return 32
    // } else {
    //   return 50
    // }
  }

  //根据工艺流程构建网格数据
  buildMeshData() {
    /***构建cell网格***/

    let aabb = this.cell.bounding_box()
    if (!aabb) return
    let expand = this.expand

    let points = getRectanglePoints(aabb, expand)
    this.rootBox = new QGdstk.Polygon(points)
    let baseDepth = this.WORK_PROCEDURE[0].depth

    this.quadTree = new CellQuadTree(this.cell, this.cellPolygons, this.WORK_PROCEDURE)
    // this.octTree = new CellOctTree(this.cell, this.WORK_PROCEDURE, this.expand)
    // this.rootBox = new QGdstk.Polygon([
    //   [-500, 500],
    //   [500, 500],
    //   [500, -500],
    //   [-500, -500],
    // ])
    //底部硅铝生成
    this.groups[0].add(generateExtrudeMesh(this.rootBox, 0, baseDepth, this.WORK_PROCEDURE[0].material))
    this.groups[1].add(generateExtrudeMesh(this.rootBox, 0, this.WORK_PROCEDURE[1].depth, this.WORK_PROCEDURE[1].material, baseDepth))

    let eatLayer = this.WORK_PROCEDURE[2].layerNumber

    let divid_size = this.calDividSize(eatLayer) //底座切分系数

    // let al1_mesh_divid = dividRectMesh(this.rootBox.bounding_box(), divid_size, this.WORK_PROCEDURE[1], baseDepth) //3D
    let al1_polygon_divid = dividRectPolygon(this.rootBox.bounding_box(), divid_size) //2D


    // let etching_result = subtractEtchingMesh(al1_mesh_divid, this.quadTree) //刻蚀 3D
    let etching_result_polygon = subtractEtchingPolygon(al1_polygon_divid, this.quadTree, this.WORK_PROCEDURE[1], eatLayer, baseDepth) //刻蚀
    let etching_result = etching_result_polygon


    etching_result.forEach(mesh => {
      mesh.depth = this.WORK_PROCEDURE[2].depth //高度
      mesh.layerNumber = this.WORK_PROCEDURE[2].layerNumber
      mesh.structurMaterial = this.WORK_PROCEDURE[2].structurMaterial
      // mesh.bottom = baseDepth //底部
      // mesh.top = mesh.depth + mesh.bottom //顶部
      this.groups[2].add(mesh)
      // mesh.geometry.computeBoundingBox()
      // mesh.box = new THREE.Box3().setFromObject(mesh)
      // this.octTree.addNode(mesh)
    })

    // let box3D = {
    //   min: {
    //     x: 0,
    //     y: 0,
    //     z: 0,
    //   },
    //   max: {
    //     x: 100,
    //     y: 100,
    //     z: 100,
    //   },
    // }

    //结节生成
    console.time('结节生成')
    //第一层
    let polygons = this.cellPolygons
    // let junction1__polygons1 = polygons.filter(polygon => polygon.layer == 15)
    // generateTargetLayerMesh(this.groups[3][0], junction1__polygons1, this.WORK_PROCEDURE[3][0], baseDepth)
    // this.groups[3][0] = depositMesh(this.octTree, baseDepth, this.groups[3][0], [this.groups[2]], this.WORK_PROCEDURE[3][0].material) //沉积
    // this.addMeshToOctTree(this.groups[3][0].children)

    // let junction1__polygons2 = polygons.filter(polygon => polygon.layer == 13)
    // generateTargetLayerMesh(this.groups[3][1], junction1__polygons2, this.WORK_PROCEDURE[3][1], baseDepth)
    // // overlapCalculation(this.groups[3][1], this.groups[3][0], WORK_PROCEDURE[3].depth)

    // this.groups[3][1] = depositMesh(this.octTree, baseDepth, this.groups[3][1], [this.groups[2]], this.WORK_PROCEDURE[3][1].material) //沉积
    // this.addMeshToOctTree(this.groups[3][1].children)
    // //第二层
    // let junction2__polygons1 = polygons.filter(polygon => polygon.layer == 16)
    // generateTargetLayerMesh(this.groups[4][0], junction2__polygons1, this.WORK_PROCEDURE[4][0], baseDepth)
    // this.groups[4][0] = depositMesh(this.octTree, baseDepth, this.groups[4][0], [this.groups[2], this.groups[3][0], this.groups[3][1]], this.WORK_PROCEDURE[4][0].material) //沉积
    // this.addMeshToOctTree(this.groups[4][0].children)

    // let junction2__polygons2 = polygons.filter(polygon => polygon.layer == 13)
    // generateTargetLayerMesh(this.groups[4][1], junction2__polygons2, this.WORK_PROCEDURE[4][1], baseDepth)
    // this.groups[4][1] = depositMesh(this.octTree, baseDepth, this.groups[4][1], [this.groups[2], this.groups[3][1]], this.WORK_PROCEDURE[4][1].material) //沉积
    // this.addMeshToOctTree(this.groups[4][1].children)

    // //第三层
    // let bandage_colum_polygons = polygons.filter(polygon => polygon.layer == 14)
    // generateTargetLayerMesh(this.groups[5], bandage_colum_polygons, this.WORK_PROCEDURE[5], baseDepth, 26) //保证沉积运算迭代正确减少厚度，沉积完成后重新根据系数缩放厚度 //baseDepth + 26 + 69 //this.WORK_PROCEDURE[0].depth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][0].depth + this.WORK_PROCEDURE[4][0].depth
    // // generateTargetLayerMesh(this.groups[5], bandage_colum_polygons, this.WORK_PROCEDURE[5], baseDepth)
    // this.groups[5] = depositMesh(this.octTree, baseDepth, this.groups[5], [this.groups[2], this.groups[3][0], this.groups[3][1], this.groups[4][1]], this.WORK_PROCEDURE[5].material)
    // this.groups[5].children.forEach(mesh => {
    //   this.scaleMesh(mesh, 9.615)
    // })
    console.timeEnd('结节生成')

    console.time('结节生成2D')
    //结节2D布尔运算测试
    //第一层
    let al__polygons = polygons.filter(polygon => polygon.layer == eatLayer)
    let junction1__polygons1 = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[3][0].layerNumber)
    let res = [[], []]
    if (junction1__polygons1.length) {
      res = depositPolygons(junction1__polygons1, al__polygons) //2D布尔运算 [相交，相减]
      generateTargetLayerMesh(this.groups[3][0], res[0], this.WORK_PROCEDURE[3][0], baseDepth)
      generateTargetLayerMesh(this.groups[3][0], res[1], this.WORK_PROCEDURE[3][0], baseDepth + this.WORK_PROCEDURE[1].depth)
    }

    let junction1__polygons2 = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[3][1].layerNumber)
    let res2 = [[], []]
    if (junction1__polygons2.length) {
      res2 = depositPolygons(junction1__polygons2, al__polygons) //2D布尔运算 [相交，相减]

      generateTargetLayerMesh(this.groups[3][1], res2[0], this.WORK_PROCEDURE[3][1], baseDepth)
      generateTargetLayerMesh(this.groups[3][1], res2[1], this.WORK_PROCEDURE[3][1], baseDepth + this.WORK_PROCEDURE[1].depth)
    }

    //第二层
    let junction2__polygons1 = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[4][0].layerNumber)
    let res3 = [[], []]
    let res3_1_1 = [[], []]
    let res3_1_2 = [[], []]
    if (junction2__polygons1.length) {
      res3 = depositPolygons(junction2__polygons1, al__polygons) //2D布尔运算 [相交，相减]
      res3_1_1 = depositPolygons(res3[0], [...res[0], ...res2[0]]) //硅表面
      generateTargetLayerMesh(this.groups[4][0], res3_1_1[0], this.WORK_PROCEDURE[4][0], baseDepth + this.WORK_PROCEDURE[3][0].depth)
      generateTargetLayerMesh(this.groups[4][0], res3_1_1[1], this.WORK_PROCEDURE[4][0], baseDepth)

      res3_1_2 = depositPolygons(res3[1], [...res[1], ...res2[1]]) //铝表面
      generateTargetLayerMesh(this.groups[4][0], res3_1_2[0], this.WORK_PROCEDURE[4][0], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][0].depth)
      generateTargetLayerMesh(this.groups[4][0], res3_1_2[1], this.WORK_PROCEDURE[4][0], baseDepth + this.WORK_PROCEDURE[1].depth)

      let junction2__polygons2 = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[4][1].layerNumber)
      generateTargetLayerMesh(this.groups[4][1], res2[0], this.WORK_PROCEDURE[4][1], baseDepth + this.WORK_PROCEDURE[3][1].depth)
      generateTargetLayerMesh(this.groups[4][1], res2[1], this.WORK_PROCEDURE[4][1], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][1].depth)
    }

    //第三层
    let junction3__polygons1 = polygons.filter(polygon => polygon.layer == 14)
    if (junction3__polygons1.length) {
      let res4 = depositPolygons(junction3__polygons1, al__polygons) //2D布尔运算
      //硅上结构
      let res4_1 = depositPolygons(res4[0], res2[0])
      generateTargetLayerMesh(this.groups[5], res4_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[3][1].depth + this.WORK_PROCEDURE[4][1].depth) //最高处

      let res4_1_1 = depositPolygons(res4_1[1], res3_1_1[0])
      generateTargetLayerMesh(this.groups[5], res4_1_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[3][0].depth + +this.WORK_PROCEDURE[4][0].depth)

      let res4_1_1_1 = depositPolygons(res4_1_1[1], res3[0])
      generateTargetLayerMesh(this.groups[5], res4_1_1_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[4][0].depth)

      let res4_1_1_1_1 = depositPolygons(res4_1_1_1[1], res[0])
      generateTargetLayerMesh(this.groups[5], res4_1_1_1_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[3][0].depth)
      generateTargetLayerMesh(this.groups[5], res4_1_1_1_1[1], this.WORK_PROCEDURE[5], baseDepth)

      //铝上结构
      let res4_2 = depositPolygons(res4[1], res2[1])
      generateTargetLayerMesh(this.groups[5], res4_2[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][1].depth + this.WORK_PROCEDURE[4][1].depth) //最高处

      let res4_2_1 = depositPolygons(res4_2[1], res3_1_2[0])
      generateTargetLayerMesh(this.groups[5], res4_2_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][0].depth + +this.WORK_PROCEDURE[4][0].depth)

      let res4_2_1_1 = depositPolygons(res4_2_1[1], res3_1_2[1])
      generateTargetLayerMesh(this.groups[5], res4_2_1_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[4][0].depth)

      let res4_2_1_1_1 = depositPolygons(res4_2_1_1[1], res[0])
      generateTargetLayerMesh(this.groups[5], res4_2_1_1_1[0], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[3][0].depth)
      generateTargetLayerMesh(this.groups[5], res4_2_1_1_1[1], this.WORK_PROCEDURE[5], baseDepth + this.WORK_PROCEDURE[1].depth)
    }

    console.timeEnd('结节生成2D')

    //空气桥生成
    let air_bridge_colum_polygons = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[6].layerNumber)
    generateTargetLayerMesh(this.groups[6], air_bridge_colum_polygons, this.WORK_PROCEDURE[6], baseDepth + this.WORK_PROCEDURE[1].depth)
    let air_bridge_plane_polygons = polygons.filter(polygon => polygon.layer == this.WORK_PROCEDURE[7].layerNumber)
    generateAirBridgePlanerMesh(this.groups[7], air_bridge_plane_polygons, this.WORK_PROCEDURE[7], baseDepth + this.WORK_PROCEDURE[1].depth + this.WORK_PROCEDURE[6].depth, this.WORK_PROCEDURE[6].layerNumber, this.quadTree)
    let meshes = this.quadTree.queryByAABB(aabb)
    meshes.forEach(mesh => this.groups[3].add(mesh))
    this.groups.forEach(group => {
      // group.scale.set(0.001, 0.001, 0.001)
      if (Array.isArray(group)) {
        group.forEach(g => (g.rotation.x = -Math.PI / 2))
      } else {
        group.rotation.x = -Math.PI / 2
      }
    })
  }

  //重置模型
  resetModel(id) {
    this.procedureModelGenerate(id)
    this.resetCutGroups()
  }

  //隐藏切割盒子
  hideCutBox(flag) {
    this.showCutBox = flag
    this.scene.remove(this.cutBoxGroup)
    if (this.showCutBox) {
      this.scene.add(this.cutBoxGroup)
    }
  }

  //拉伸场景
  scaleChange(s) {
    this.groups.forEach(g => {
      if (Array.isArray(g)) {
        g.forEach(sub => {
          sub.scale.z = s
        })
      } else {
        g.scale.z = s
      }
    })
    this.cutGroups.forEach(g => {
      if (Array.isArray(g)) {
        g.forEach(sub => {
          sub.scale.z = s
        })
      } else {
        g.scale.z = s
      }
    })
    this.cutBoxGroup.scale.z = s
  }
  //重置相机位置
  restCameraPosition() {
    let origin = new THREE.Vector3()
    let aabb = this.cell.bounding_box()
    if (aabb) {
      aabb[0][0] -= this.expand
      aabb[0][1] -= this.expand
      aabb[1][0] += this.expand
      aabb[1][1] += this.expand
      let w = aabb[1][0] - aabb[0][0]
      let h = aabb[1][1] - aabb[0][1]
      let y = (w + h) / 2
      this.camera.position.x = aabb[0][0]
      this.camera.position.y = y
      this.camera.position.z = -aabb[0][1]
      origin.x = aabb[0][0] + w / 2
      origin.z = -(aabb[0][1] + h / 2)
    } else {
      this.camera.position.x = 100
      this.camera.position.y = 100
      this.camera.position.z = 100
    }

    if (this.controls) {
      this.controls.target = origin
      if (this.controls.lookAt) {
        this.controls.lookAt(origin)
      }
    }
  }
  //画板宽度缩放
  updateViewWidth() {
    let container = document.getElementById('container-3d')
    this.renderer.setSize(container.clientWidth, container.clientHeight)
    // this.camera.aspect = container.clientWidth/container.clientHeight
    this.onWindowResize()
  }

  resetCutGroups() {
    let res = []
    this.groups.forEach(group => {
      if (Array.isArray(group)) {
        let subs = []
        group.forEach(sub => {
          let copyGroup = sub.clone(true)
          copyGroup.layerNumber = sub.layerNumber
          copyGroup.structurMaterial = sub.structurMaterial
          subs.push(copyGroup)
        })
        res.push(subs)
      } else {
        let copyGroup = group.clone(true)
        copyGroup.layerNumber = group.layerNumber
        copyGroup.structurMaterial = group.structurMaterial
        res.push(copyGroup)
      }
    })
    this.cutGroups = res
  }

  scaleMesh(mesh, scale_z) {

    let geometry = mesh.geometry
    geometry.computeBoundingBox()
    let x = geometry.boundingBox.min.x
    let y = geometry.boundingBox.min.y
    let z = geometry.boundingBox.min.z
    geometry.translate(-x, -y, -z)
    geometry.scale(1, 1, scale_z)
    geometry.translate(x, y, z)
    // geometry.applyMatrix(new THREE.Matrix4().makeTranslation(x, y, z))
    // geometry.applyMatrix(new THREE.Matrix4().makeTranslation(0, 0, -10))

    // mesh.translateX(-x)
    // mesh.translateY(-y)
    // mesh.translateZ(-z)
    // mesh.scale.set(1, 1, scale_z)
    // mesh.translateX(x)
    // mesh.translateY(y)
    // mesh.translateZ(z)
  }
  addMeshToOctTree(meshs) {
    for (let i = 0; i < meshs.length; i++) {
      this.octTree.addNode(meshs[i])
    }
  }

  setGroupMeshProp() {
    function setMeshProp(mesh) {
      mesh.castShadow = true
      mesh.receiveShadow = true
    }
    this.groups.forEach(group => {
      if (Array.isArray(group)) {
        group.forEach(sub => {
          sub.children.forEach(mesh => {
            setMeshProp(mesh)
          })
        })
      } else {
        group.children.forEach(mesh => {
          setMeshProp(mesh)
        })
      }
    })
  }

  debugShadow() {
    // let mat = new THREE.MeshPhysicalMaterial({ color: '#18b1b4' })
    // // this.csm.setupMaterial(mat)
    // const geometry1 = new THREE.BoxGeometry(1000, 10, 1000)
    // const box1 = new THREE.Mesh(geometry1, mat)
    // box1.position.y = -10
    // box1.receiveShadow = true
    // box1.castShadow = true
    // box1.updateMatrix()
    // this.scene.add(box1)
    // const geometry2 = new THREE.BoxGeometry(5, 5, 5)
    // const box2 = new THREE.Mesh(geometry2, mat)
    // box2.receiveShadow = true
    // box2.castShadow = true
    // box2.position.y = 5
    // box2.updateMatrix()
    // this.scene.add(box2)

  }

  clear() {
    this.deleteGroups()
    this.clearScene()
    this.groups = []
    this.cutGroups = []
  }
}
